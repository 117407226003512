import { html as uHtml } from 'uhtml';
import ImageWMS from 'ol/source/ImageWMS';
import tippy from 'tippy.js';
import GirafeHTMLElement from '../../base/GirafeHTMLElement';
import Layer from '../../models/layers/layer';
import LayerManager from '../../tools/layermanager';
import LayerWms from '../../models/layers/layerwms';
import LayerLocalFile from '../../models/layers/layerlocalfile';
import QueryBuilderComponent from '../querybuilder/component';
import GeoEvents from '../../models/events';

class TreeViewItemComponent extends GirafeHTMLElement {
  template = () => { return uHtml`<style>
.item {
  color: #999;
  border-top: solid 1px #ddd;
  line-height: 1.3rem;
  display: flex;
  flex-wrap: wrap;
}

.hidden {
  display: none;
}

.invisible {
  visibility: hidden;
}

.selectable {
  cursor: pointer;
}

.selectable:hover {
  color: var(--text-color);
}

.selected {
  cursor: pointer;
  color: var(--text-color);
}

i {
  width: 0.8rem;
  text-align: center;
  min-width: 0;
  padding-top: 0.45rem;
  margin-left: 0.3rem;
}

.expand-spacer {
  order: 1;
  width: 0.5rem;
}

.circle-on,
.circle-off {
  order: 2;
  padding-top: 0.25rem;
  margin-left: 0.2rem;
  margin-right: 0.1rem;
  cursor: pointer;
}

.legend-icon {
  order: 2;
  max-height: 1rem;
  padding-top: 0.25rem;
  margin-left: 0.2rem;
  cursor: pointer;
  object-fit: none;
}

.error {
  order: 3;
  color: #b00;
}

.text {
  order: 4;
  display: inline-block;
  margin: 0.2rem;
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 0;
}

.text.filtered {
  background-color: var(--bkg-color-grad2);
  color: var(--text-color-grad2);
}

.resolution {
  order: 5;
}

.swipe-left {
  order: 5;
}

.swipe-right {
  order: 7;
}

.movedown {
  order: 8;
}

.moveup {
  order: 9;
}

.opacity {
  order: 10;
}

.filter {
  order: 11;
}

.legend {
  order: 12;
}

.item i.advanced {
  display: none;
}

.item.advanced i.advanced {
  display: block;
}

.legend-file,
.legend-image {
  margin-left: 1rem;
}

.legend-file {
  font-style: italic;
}

</style>
<link rel="stylesheet" href="lib/font-gis/font-gis.css" />
<link rel="stylesheet" href="lib/fontawesome/css/all.min.css" />
<link rel="stylesheet" href="lib/tippy.js/tippy.css" />
<link rel="stylesheet" href="lib/tippy.js/backdrop.css" />
<link rel="stylesheet" href="lib/tippy.js/border.css" />
<link rel="stylesheet" href="lib/tippy.js/svg-arrow.css" />

<div class="${this.state.treeview.advanced ? 'item advanced' : 'item'}">
  <!-- spacer for alignment -->
  <div class="invisible expand-spacer"></div>

  <!-- Bullet or Checkbox or Icon -->
  <img
    class="${this.layer instanceof LayerWms && !this.configManager.Config.treeview.useCheckboxes && this.configManager.Config.treeview.useLegendIcons && this.iconUrl ? 'legend-icon' : 'legend-icon hidden'}"
    alt="${'Icon for ' + this.layer.name}"
    onclick="${() => this.toggle()}"
    src="${this.iconUrl}" />
  <div
    class="${(!(this.layer instanceof LayerWms) || !this.configManager.Config.treeview.useLegendIcons || !this.iconUrl || this.configManager.Config.treeview.useCheckboxes) && this.layer.activeState === 'off' ? 'circle-off selectable' : 'circle-off hidden'}"
    onclick="${() => this.toggle()}">
    <i
      class="${this.configManager.Config.treeview.useCheckboxes ? 'fa-lg fa-regular fa-square selbox' : 'fa-xs fa-regular fa-circle selcircle'}"></i>
  </div>
  <div
    class="${(!(this.layer instanceof LayerWms) || !this.configManager.Config.treeview.useLegendIcons || !this.iconUrl || this.configManager.Config.treeview.useCheckboxes) && this.layer.activeState === 'on' ? 'circle-on selected' : 'circle-on hidden'}"
    onclick="${() => this.toggle()}">
    <i
      class="${this.configManager.Config.treeview.useCheckboxes ? 'fa-lg fa-solid fa-square-check selbox' : 'fa-xs fa-solid fa-circle selcircle'}"></i>
  </div>

  <!-- Error icon -->
  <i
    class="${this.layer.hasError ? 'fa-solid fa-circle-exclamation tool error' : 'error hidden'}"
    tip="${this.layer.errorMessage}"
    tip-theme="error"></i>

  <!-- Label -->
  <span
    i18n="${this.layer.name}"
    class="${this.layer.activeState === 'on' ? ((this.layerManager.isLayerWithFilter(this.layer) && this.layer.hasFilter) ? 'text selected filtered' : 'text selected') : 'text selectable'}"
    tip="${(this.layerManager.isLayerWithFilter(this.layer) && this.layer.hasFilter) ? 'This layer is filtered.' : ''}"
    onclick="${() => this.toggle()}"
    >${this.layer.name}</span
  >

  <!-- zoom to resolution -->
  <i
    class="${this.layer instanceof LayerWms && this.layer.hasRestrictedResolution() ? 'fa-solid fa-magnifying-glass tool selectable resolution' : 'resolution hidden'}"
    tip="Zoom to visible resolution"
    onclick="${() => this.zoomToVisibleResolution()}">
  </i>

  <!-- zoom to full extent -->
  <i
    class="${this.layer instanceof LayerLocalFile ? 'fa-solid fa-magnifying-glass tool selectable resolution' : 'resolution hidden'}"
    tip="Zoom to full extent"
    onclick="${() => this.zoomToFullExtent()}">
  </i>

  <!-- swipe icons -->
  <i
    class="fa-solid fa-arrow-left tool selectable swipe-left advanced"
    tip="Swipe layer on the left"
    onclick="${() => this.swipeLayer('left')}"></i>
  <i
    class="fa-solid fa-arrow-right tool selectable swipe-right advanced"
    tip="Swipe layer on the right"
    onclick="${() => this.swipeLayer('right')}"></i>

  <!-- move icons -->
  <i class="fa-solid fa-caret-down advanced tool movedown selectable" tip="Move this layer down"></i>
  <i class="fa-solid fa-caret-up advanced tool moveup selectable" tip="Move this layer up"></i>

  <i id="opacity" class="fa-regular fa-sun tool selectable opacity advanced" tip="Control opacity"></i>

  <!-- Filter -->
  <i
    id="filter"
    class="${this.layer instanceof LayerWms && this.layer.queryable ? 'fa-solid fa-filter tool selectable filter advanced' : 'filter invisible'}"
    tip="Filter layer"></i>

  <!-- Toggle legend icon -->
  <div
    class="${this.layer instanceof LayerLocalFile || (this.layer instanceof LayerWms && this.layer.legend) ? 'legend selectable' : 'legend invisible'}">
    <i
      tip="Toggle legend"
      class="${this.layerManager.isLayerWithLegend(this.layer) && this.layer.isLegendExpanded ? 'fa-solid fa-bars fa-rotate-270 tool selectable' : 'fa-solid fa-bars tool selectable'}"
      onclick="${() => {if (this.layerManager.isLayerWithLegend(this.layer)) {this.layer.isLegendExpanded = !this.layer.isLegendExpanded}}}"></i>
  </div>
</div>

${(this.layer as LayerWms)?.layers?.split(',').map((l:string) => uHtml`<img
  class="${this.layer instanceof LayerWms && this.layer.legend && this.layer.isLegendExpanded ? 'legend-image' : 'legend-image hidden'}"
  alt="${'Legend for ' + l}"
  src="${this.legendUrls[l]}" />` )}

<div
  class="${this.layer instanceof LayerLocalFile && this.layer.isLegendExpanded ? 'legend-file' : 'legend-file hidden'}">
  <span>File from </span><span>${(this.layer as LayerLocalFile).lastModifiedDate}</span><span> [</span
  ><span>${(this.layer as LayerLocalFile)._features?.length} </span><span>features.]</span>
</div>
`; }
  

  layerManager: LayerManager;

  layer: Layer;
  iconUrl: string | null = null;
  legendUrls: Record<string, string> = {};

  constructor(layer: Layer) {
    super('treeviewitem');

    this.layerManager = LayerManager.getInstance();
    this.layer = layer;
  }

  render() {
    // If we come from a html element, the layer was not defined in the constructor
    // And we have to set the layer using the id passed to the layerid attribute
    const layerId = this.getAttribute('layerid');
    if (layerId) {
      this.layer = this.layerManager.getTreeItem(layerId) as Layer;
      if (this.layer instanceof LayerWms) {
        // Get Legend Url
        this.setWmsLegend();
      }
    }
    super.render();
    this.createOpacityTooltip();
    this.createFilterTooltip();
    this.createTooltips();
  }

  setWmsLegend() {
    if (!(this.layer instanceof LayerWms)) {
      // nothing to do if it's not a WMS
      return;
    }

    // Manage Icon URL
    if (this.layer.iconUrl) {
      // A custom legend icon has been defined and can be displayed
      this.iconUrl = this.layer.iconUrl;
    } else if (this.layer.legendRule) {
      // We need to get the legend icon URL from WMS
      this.iconUrl = Object.values(this.getLegendImageUrlFromWms(true))[0];
    } else {
      // All other cases, we do not have any icon for the layer
      // => A simple selection icon will be rendered
      this.iconUrl = null;
    }

    // Manage Legend
    if (this.layer.legend) {
      if (this.layer.legendImage) {
        // TODO REG : remove this ! when a refactoring of the Layer class has been done
        this.legendUrls[this.layer.layers!] = this.layer.legendImage;
      } else {
        this.legendUrls = this.getLegendImageUrlFromWms(false);
      }
    }
  }

  getLegendImageUrlFromWms(iconOnly: boolean): Record<string, string> {
    if (!(this.layer instanceof LayerWms)) {
      throw new Error(`${this.layer.name} is not a WMS layer, this method should not be called.`);
    }

    const legends: Record<string, string> = {};
    // TODO REG : remove this ! when a reactoring of the Layer class has been done
    for (const l of this.layer.layers!.split(',')) {
      const wmsSource = new ImageWMS({
        url: this.layer.url,
        params: { LAYERS: l },
        ratio: 1
      });

      let graphicUrl = wmsSource.getLegendUrl(this.state.position.resolution);
      if (!graphicUrl) {
        console.error(`The URL for legend of layer ${l} could not be calculated.`);
        legends[l] = '';
        continue;
      }

      if (!graphicUrl.toLowerCase().includes('sld_version')) {
        // Add SLD_Version (it is mandatory, but openlayers do not seems to set it in the URL)
        graphicUrl += '&SLD_Version=1.1.0';
      }

      if (iconOnly) {
        if (!this.isNullOrUndefined(this.layer.legendRule)) {
          graphicUrl += '&RULE=' + encodeURIComponent(this.layer.legendRule!);
        }
        graphicUrl += '&HEIGHT=' + this.configManager.Config.treeview.defaultIconSize.height;
        graphicUrl += '&WIDTH=' + this.configManager.Config.treeview.defaultIconSize.width;
      }

      legends[l] = graphicUrl;
    }

    return legends;
  }

  createOpacityTooltip() {
    const el = this.shadow.getElementById('opacity');
    tippy(el, {
      trigger: 'click',
      arrow: true,
      interactive: true,
      theme: 'light',
      placement: 'bottom-end',
      content: (_reference: object) => {
        const slider = document.createElement('input');
        slider.type = 'range';
        slider.className = 'slider';
        slider.min = '0';
        slider.max = '20';
        slider.value = (this.layer.opacity * 20).toString();
        slider.oninput = (_e) => (this.layer.opacity = parseInt(slider.value) / 20);
        return slider;
      }
    });
  }

  createFilterTooltip() {
    const el = this.shadow.getElementById('filter');
    tippy(el, {
      trigger: 'click',
      arrow: true,
      interactive: true,
      theme: 'light',
      placement: 'right',
      appendTo: document.body,
      content: (_reference: object) => {
        const filterbox = new QueryBuilderComponent(this.layer as LayerWms);
        return filterbox;
      }
    });
  }

  registerEvents() {
    this.stateManager.subscribe(
      /layers\.layersList\..*\.isLegendExpanded/,
      (_oldValue: boolean, _newValue: boolean, layer: Layer) => this.refreshRender(layer)
    );
    this.stateManager.subscribe(
      /layers\.layersList\..*\.activeState/,
      (_oldValue: boolean, _newValue: boolean, layer: Layer) => this.refreshRender(layer)
    );
    this.stateManager.subscribe(
      /layers\.layersList\..*\.hasError/,
      (_oldValue: boolean, _newValue: boolean, layer: Layer) => this.refreshRender(layer)
    );
    this.stateManager.subscribe(
      /layers\.layersList\..*\.errorMessage/,
      (_oldValue: boolean, _newValue: boolean, layer: Layer) => this.refreshRender(layer)
    );
    this.stateManager.subscribe(
      /layers\.layersList\..*\.filter/,
      (_oldValue: boolean, _newValue: boolean, layer: Layer) => this.refreshRender(layer)
    );
    this.stateManager.subscribe('treeview.advanced', () => super.render());
    this.stateManager.subscribe('position.resolution', () => this.refreshLegends());
  }

  refreshLegends() {
    if (this.layer instanceof LayerWms) {
      this.setWmsLegend();
    }
    super.render();
  }

  refreshRender(layer: Layer) {
    if (layer === this.layer) {
      super.render();
      this.createTooltips();
    }
  }

  createTooltips() {
    super.activateTooltips(false, [800, 0], 'right');
  }

  toggle(state?: 'on' | 'off') {
    this.layerManager.toggleLayer(this.layer, state);
  }

  zoomToVisibleResolution() {
    if (!(this.layer instanceof LayerWms)) {
      throw new Error(`${this.layer.name} is not a WMS layer, this method should not be called here.`);
    }

    // Because of rounding errors (for example 1.59 becomes 1.589999999999998),
    // we zoom a bit more than just the max resolution.
    // For the moment we try with 10% more
    if (this.layer.maxResolution) {
      const resolution = this.layer.maxResolution - (10 / 100) * this.layer.maxResolution;
      this.state.position.resolution = resolution;
    }
  }

  zoomToFullExtent() {
    if (!(this.layer instanceof LayerLocalFile)) {
      throw new Error(`${this.layer.name} is not a LocalFile layer, this method should not be called here.`);
    }

    this.messageManager.sendMessage({ action: GeoEvents.zoomToExtent, extent: this.layer.extent });
  }

  swipeLayer(side: 'left' | 'right') {
    if (this.layer.activeState === 'off') {
      this.toggle('on');
    }

    const otherSide = side === 'left' ? 'right' : 'left';
    const newSwipedLayers: Record<'left' | 'right', Array<Layer>> = {
      left: [],
      right: []
    };
    // If the object is already present in the other side, we remove it
    newSwipedLayers[otherSide] = this.state.layers.swipedLayers[otherSide].filter((l: Layer) => {
      return l.treeItemId !== this.layer.treeItemId;
    });
    // Then, we add it to right side
    newSwipedLayers[side] = [...this.state.layers.swipedLayers[side]];
    newSwipedLayers[side].push(this.layer);
    // Lastly, we update the state
    this.state.layers.swipedLayers = newSwipedLayers;
  }

  connectedCallback() {
    this.loadConfig().then(() => {
      this.render();
      super.girafeTranslate();
      this.registerEvents();
    });
  }
}

export default TreeViewItemComponent;
