import { html as uHtml } from 'uhtml';
import GirafeHTMLElement from '../../base/GirafeHTMLElement';
import { v4 as uuidv4 } from 'uuid';

class VideoRecordComponent extends GirafeHTMLElement {
  template = () => { return uHtml`<style>
.hidden {
  display: none;
}

</style>
<div id="content">
  <div class="${this.status === 'downloaded' ? '' : 'hidden'}">
    <girafe-button icon-style="fa-2xl fa-solid fa-video" onclick="${() => this.startRecording()}"></girafe-button>
  </div>
  <div class="${this.status === 'recording' ? '' : 'hidden'}">
    <girafe-button icon-style="fa-2xl fa-solid fa-circle-stop" onclick="${() => this.stopRecording()}"></girafe-button>
  </div>
  <div class="${this.status === 'recorded' ? '' : 'hidden'}">
    <girafe-button icon-style="fa-2xl fa-solid fa-circle-down" onclick="${() => this.downloadRecord()}"></girafe-button>
  </div>
</div>
`; }
  

  status: 'downloaded' | 'recording' | 'recorded' = 'downloaded';
  mediaRecorder: MediaRecorder | null = null;
  chunks: Blob[] = [];
  stream: MediaStream | null = null;

  constructor() {
    super('videorecord');
  }

  connectedCallback() {
    this.loadConfig().then(() => {
      super.render();
      super.girafeTranslate();
    });
  }

  async startRecording() {
    this.status = 'recording';
    super.render();

    this.chunks = [];
    if (this.mediaRecorder) {
      // Try to start the recorder
      try {
        this.mediaRecorder.start();
      } catch {
        // If an exception is thrown, if probaly means that the screen share was stopped.
        this.mediaRecorder = null;
      }
    }

    if (!this.mediaRecorder) {
      if (await this.initializeMediaRecorder()) {
        this.mediaRecorder!.start();
      }
    }
  }

  async initializeMediaRecorder() {
    try {
      this.stream = await navigator.mediaDevices.getDisplayMedia({
        video: {
          width: { ideal: 1920 },
          height: { ideal: 1080 },
          frameRate: { ideal: 30 }
        }
      });
      this.mediaRecorder = new MediaRecorder(this.stream, {
        mimeType: 'video/webm; codecs:vp9',
        bitsPerSecond: 5000000 // 5 Mbps
      });
      this.mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          this.chunks.push(event.data);
        }
      };

      return true;
    } catch (exception) {
      console.warn('Unable to activate recording : ' + exception);
      this.status = 'downloaded';
      super.render();
    }

    return false;
  }

  stopRecording() {
    this.status = 'recorded';
    super.render();
    this.mediaRecorder?.stop();
  }

  downloadRecord() {
    this.status = 'downloaded';
    super.render();

    // Create video blob
    const blob = new Blob(this.chunks, { type: 'video/webm' });
    const filename: string = uuidv4() + '.webm';
    const videoURL = URL.createObjectURL(blob);

    // Create download link
    const downloadLink: HTMLAnchorElement = document.createElement('a');
    downloadLink.style.display = 'none';
    downloadLink.href = videoURL;
    downloadLink.download = filename;

    // Simulate click on download link
    document.body.appendChild(downloadLink);
    downloadLink.click();

    // Clear
    document.body.removeChild(downloadLink);
    window.URL.revokeObjectURL(videoURL);
  }
}

export default VideoRecordComponent;
