import { html as uHtml } from 'uhtml';
import GirafeHTMLElement from '../../base/GirafeHTMLElement';
import MenuButtonComponent from '../menubutton/component';

class LanguageComponent extends GirafeHTMLElement {
  template = () => { return uHtml`<style>

</style>
<girafe-menu-button id="menu-button" icon-style="fa-xl fa-solid fa-language" open="left">
  <div slot="menu-content">
    ${this.languages.map((language) => uHtml`
    <girafe-button
      text="${language.toUpperCase()}"
      icon-style="fa-xl fa-solid fa-language"
      onclick="${() => this.changeLanguage(language)}"></girafe-button
    >` )}
  </div>
</girafe-menu-button>
`; }
  

  languages: string[] = [];
  #menuButton?: MenuButtonComponent;

  constructor() {
    super('language');
  }

  get menuButton() {
    if (!this.#menuButton) {
      throw new Error('You called menuButton before render');
    }
    return this.#menuButton;
  }

  render() {
    // Get languages list and store it
    for (const key in this.configManager.Config.languages) {
      if (!key.startsWith('//') && key !== 'defaultLanguage') {
        this.languages.push(key);
      }
    }

    super.render();
    this.#menuButton = this.shadow.querySelector('#menu-button')!;

    // Hide menu button if only one language
    if (this.languages.length == 1) {
      this.menuButton.hide();
    }
  }

  changeLanguage(language: string) {
    console.log('change language', language);
    this.state.language = language;
  }

  registerEvents() {
    this.stateManager.subscribe('language', (_oldLanguage: string, newLanguage: string) =>
      this.onTranslate(newLanguage)
    );
  }

  onTranslate(language: string) {
    this.menuButton.setText(language.toUpperCase());
    this.menuButton.closeMenu();
  }

  connectedCallback() {
    this.loadConfig().then(() => {
      this.render();
      super.girafeTranslate();
      this.registerEvents();
    });
  }
}

export default LanguageComponent;
