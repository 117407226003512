import { html as uHtml } from 'uhtml';
import GirafeHTMLElement from '../../base/GirafeHTMLElement';
import { niceCoordinates } from '../../tools/geometrytools';

class CoordinateComponent extends GirafeHTMLElement {
  template = () => { return uHtml`<style>
#content {
  background: var(--t-bkg);
  color: var(--text-color);
  width: -moz-fit-content;
  width: fit-content;
  white-space: nowrap;
  padding: 0 0.5rem;
  font-size: 1rem;
  height: 2.3rem;
  line-height: 2.3rem;
}

span {
  font-weight: bold;
}

</style>
<div id="content">
  <span id="coords">E ${this.east} / N ${this.north}</span>
</div>
`; }
  

  #locale?: string;
  east: string | null = null;
  north: string | null = null;

  constructor() {
    super('coordinate');
  }

  get locale() {
    if (!this.#locale) {
      throw new Error('You called locale before render');
    }
    return this.#locale;
  }

  render() {
    super.render();
    this.#locale = this.configManager.Config.general.locale;
  }

  registerEvents() {
    this.stateManager.subscribe('mouseCoordinates', (_oldCoordinates: number[], newCoordinates: number[]) =>
      this.onChangeCoordinates(newCoordinates)
    );
  }

  onChangeCoordinates(coord: number[]) {
    [this.east, this.north] = niceCoordinates(coord, this.locale);
    this.render();
  }

  connectedCallback() {
    this.loadConfig().then(() => {
      this.render();
      super.girafeTranslate();
      this.registerEvents();
    });
  }
}

export default CoordinateComponent;
