import { html as uHtml } from 'uhtml';
import GirafeHTMLElement from '../../base/GirafeHTMLElement';
import ArrowBlack from './images/arrow_black.webp';
import ArrowWhite from './images/arrow_white.webp';

class HelpComponent extends GirafeHTMLElement {
  template = () => { return uHtml`<style>
#content {
  z-index: 10000;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: none;
}

#themes {
  position: absolute;
  top: 4.5rem;
  left: 0;
  width: 150px;
  height: 120px;
  background-repeat: no-repeat;
  transform: rotateY(0deg) rotateX(0deg) rotateZ(90deg);
  background-position: bottom -50px left -60px;
}

#search {
  position: absolute;
  top: 8rem;
  left: 45%;
  width: 180px;
  height: 70px;
  background-repeat: no-repeat;
  transform: rotateY(0deg) rotateX(0deg) rotateZ(90deg);
  background-position: -60px -30px;
}

#menu {
  position: absolute;
  top: 4.5rem;
  right: 0;
  width: 150px;
  height: 120px;
  background-repeat: no-repeat;
  transform: rotateY(180deg) rotateX(0deg) rotateZ(90deg);
  background-position: bottom -50px left -60px;
}

#basemap {
  position: absolute;
  bottom: 3rem;
  left: 21rem;
  width: 150px;
  height: 120px;
  background-repeat: no-repeat;
  transform: rotateY(0deg) rotateX(180deg) rotateZ(90deg);
  background-position: bottom -50px left -60px;
}

#themes-descr {
  position: absolute;
  top: 10rem;
  left: 10rem;
  color: var(--bkg-color);
  font-size: 1.2rem;
  width: 12rem;
}

#search-descr {
  position: absolute;
  top: 16rem;
  left: 45%;
  color: var(--bkg-color);
  font-size: 1.2rem;
  width: 12rem;
}

#menu-descr {
  position: absolute;
  top: 10rem;
  right: 10rem;
  color: var(--bkg-color);
  font-size: 1.2rem;
  width: 12rem;
  text-align: right;
}

#basemap-descr {
  position: absolute;
  bottom: 9rem;
  left: 31rem;
  color: var(--bkg-color);
  font-size: 1.2rem;
  width: 12rem;
}

</style>
<div id="content">
  <div id="themes"></div>
  <div id="themes-descr">
    Clicking the Topics button opens the topic overview. After selecting the desired topic, all assigned maps are
    loaded.
  </div>

  <div id="search"></div>
  <div id="search-descr">Search for a place, an address, a map or a geographical data.</div>

  <div id="menu"></div>
  <div id="menu-descr">This menu contains advanced functions such as printing, drawing, language, 3D view, etc...</div>

  <div id="basemap"></div>
  <div id="basemap-descr">Here, you can choose which background layers you want to use.</div>
</div>
`; }
  

  content!: HTMLElement;
  themes!: HTMLElement;
  search!: HTMLElement;
  menu!: HTMLElement;
  basemap!: HTMLElement;

  darkFrontendMode: boolean = false;

  arrowBlack: string = ArrowBlack;
  arrowWhite: string = ArrowWhite;
  currentArrow?: string;

  constructor() {
    super('help');
  }

  render() {
    super.render();
    this.content = this.shadow.querySelector('#content') as HTMLElement;
    // setting the images for the arrows with the imported files to use vite to serve them, because uhtml does somehow not render variables in css
    this.themes = this.shadow.querySelector('#themes') as HTMLElement;
    this.search = this.shadow.querySelector('#search') as HTMLElement;
    this.menu = this.shadow.querySelector('#menu') as HTMLElement;
    this.basemap = this.shadow.querySelector('#basemap') as HTMLElement;

    // hide and show help content depending on state
    if (!this.configManager.Config.basemaps.show) {
      (this.shadow.querySelector('#basemap') as HTMLElement).style.display = 'none';
      (this.shadow.querySelector('#basemap-descr') as HTMLElement).style.display = 'none';
    }
  }

  registerEvents() {
    this.stateManager.subscribe('interface.helpVisible', (_oldValue: boolean, newValue: boolean) =>
      this.toggleHelp(newValue)
    );
    this.stateManager.subscribe('interface.darkFrontendMode', (_oldValue: boolean, newValue: boolean) => {
      this.darkFrontendMode = newValue;
    });
    this.content.addEventListener('click', () => {
      this.state.interface.helpVisible = false;
    });
  }

  changeArrowColor() {
    // change the arrow color depending on the darkFrontendMode state
    this.darkFrontendMode = this.stateManager.state.interface.darkFrontendMode;
    this.currentArrow = this.darkFrontendMode ? this.arrowBlack : this.arrowWhite;

    this.themes.style.backgroundImage = `url(${this.currentArrow})`;
    this.search.style.backgroundImage = `url(${this.currentArrow})`;
    this.menu.style.backgroundImage = `url(${this.currentArrow})`;
    this.basemap.style.backgroundImage = `url(${this.currentArrow})`;

    this.content.style.backgroundColor = this.darkFrontendMode ? 'rgba(255, 255, 255, 0.65)' : 'rgba(0, 0, 0, 0.65)';
  }

  toggleHelp(visible: boolean) {
    if (visible) {
      this.changeArrowColor();
      this.content.style.display = 'block';
    } else {
      this.content.style.display = 'none';
    }
  }

  connectedCallback() {
    this.loadConfig().then(() => {
      this.render();
      super.girafeTranslate();
      this.registerEvents();
    });
  }
}

export default HelpComponent;
