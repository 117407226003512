import { html as uHtml } from 'uhtml';
import GirafeDraggableElement from '../../base/GirafeDraggableElement';

class SelectionWindowComponent extends GirafeDraggableElement {
  template = () => { return uHtml`<style>
#draggable {
  border: solid 1px #666;
  border-radius: 3px;
  box-shadow:
    0 1px 2px rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
  background: var(--bkg-color);
}

#header {
  padding: 0.5rem;
  cursor: move;
  z-index: 10;
  background-color: #666;
  color: var(--text-color);
  text-align: center;
}

#content {
  padding: 1rem 2rem;
  width: 26rem;
  height: 12rem;
  overflow-y: auto;
  scrollbar-width: thin;
}

table {
  border-collapse: collapse;
  width: 100%;
}

td.label {
  text-align: right;
  padding-right: 1rem;
  color: #666;
  width: 8rem;
  vertical-align: top;
}

td.label:after {
  content: ' :';
}

#footer {
  color: #aaa;
  height: 2rem;
  border-top: solid 1px #999;
}

#previous {
  padding: 0.5rem;
  cursor: pointer;
  position: absolute;
  left: 0;
  bottom: 0;
}

#next {
  padding: 0.5rem;
  cursor: pointer;
  position: absolute;
  right: 0;
  bottom: 0;
}

#counter {
  line-height: 2rem;
  text-align: center;
  margin-top: 0;
}

</style>
<link rel="stylesheet" href="lib/fontawesome/css/all.min.css" />

<div id="draggable">
  <div id="header">HEADER</div>
  <div id="content"></div>
  <div id="footer">
    <a id="previous"><i class="fa-solid fa-xl fa-circle-arrow-left"></i></a>
    <a id="next"><i class="fa-solid fa-xl fa-circle-arrow-right"></i></a>
    <p id="counter">1/5</p>
  </div>
</div>
`; }
  

  maxFeatures = 200;
  focusedIndex = 0;

  content = null;
  previousButton = null;
  nextButton = null;
  counter = null;

  constructor() {
    super('selectionwindow');
  }

  render() {
    super.render();
    this.content = this.shadow.querySelector('#content');
    this.previousButton = this.shadow.querySelector('#previous');
    this.nextButton = this.shadow.querySelector('#next');
    this.counterText = this.shadow.querySelector('#counter');
  }

  registerEvents() {
    this.previousButton.addEventListener('click', () => this.onFocusFeature(this.focusedIndex - 1));
    this.nextButton.addEventListener('click', () => this.onFocusFeature(this.focusedIndex + 1));
  }

  onFocusFeature(index) {
    this.focusedIndex = index;
    this.state.selection.focusedFeature = this.state.selection.selectedFeatures[index];

    // Title (draggable header)
    const id = this.state.selection.focusedFeature.getId();
    const featureType = id === undefined ? 'UNKNOWN' : id.split('.')[0];
    this.header.setAttribute('i18n', featureType);

    // Content
    const properties = this.state.selection.focusedFeature.getProperties();
    const table = document.createElement('table');
    for (const key in properties) {
      // Exclude openlayers properties and geometry
      // TODO REG: Find the right geometry property using WFS Capabilities
      if (key !== 'boundedBy' && key !== 'the_geom') {
        const tr = document.createElement('tr');

        const td = document.createElement('td');
        td.className = 'label';
        td.setAttribute('i18n', key);
        tr.appendChild(td);

        const val = document.createElement('td');
        val.className = 'value';
        val.innerHTML = properties[key];
        tr.appendChild(val);

        table.appendChild(tr);
      }
    }

    this.content.innerHTML = '';
    this.content.appendChild(table);

    // Enable/Disable Previous/next buttons
    this.enableNavigationButtons();

    // Translate data
    super.girafeTranslate();
  }

  enableNavigationButtons() {
    let previousDisplay = 'block';
    let nextDisplay = 'block';
    if (this.focusedIndex === 0) {
      previousDisplay = 'none';
    }
    if (this.focusedIndex === this.state.selection.selectedFeatures.length - 1) {
      nextDisplay = 'none';
    }

    this.previousButton.style.display = previousDisplay;
    this.nextButton.style.display = nextDisplay;

    // Set counter text
    if (this.state.selection.selectedFeatures.length === 1) {
      this.counterText.innerHTML = '';
    } else {
      this.counterText.innerHTML = this.focusedIndex + 1 + '/' + this.state.selection.selectedFeatures.length;
    }
  }

  connectedCallback() {
    this.loadConfig().then(() => {
      this.render();
      super.girafeTranslate();
      this.makeDraggable();
      this.registerEvents();
    });
  }
}

export default SelectionWindowComponent;
