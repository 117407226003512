import { html as uHtml } from 'uhtml';
import GirafeHTMLElement from '../../base/GirafeHTMLElement';
import MenuButtonComponent from '../menubutton/component';

class GlobeComponent extends GirafeHTMLElement {
  template = () => { return uHtml`<style>

</style>
<girafe-menu-button id="menu-button" icon-style="fa-xl fa-solid fa-globe" text="2D / 3D" open="left">
  <div slot="menu-content">
    <girafe-button icon-style="fa-xl fa-solid fa-map" text="2D" onclick="this.state.globe.display='none'">
    </girafe-button>
    <girafe-button icon-style="fa-xl fa-solid fa-right-left" text="Both" onclick="this.state.globe.display='side'">
    </girafe-button>
    <girafe-button icon-style="fa-xl fa-solid fa-earth-europe" text="3D" onclick="this.state.globe.display='full'">
    </girafe-button>
  </div>
</girafe-menu-button>
`; }
  

  #menuButton?: MenuButtonComponent;

  constructor() {
    super('globe');
  }

  get menuButton() {
    if (!this.#menuButton) {
      throw new Error('You called menuButton before render');
    }
    return this.#menuButton;
  }

  render() {
    super.render();
    this.#menuButton = this.shadow.querySelector('#menu-button')!;
  }

  connectedCallback() {
    this.loadConfig().then(() => {
      this.render();
      super.girafeTranslate();
    });
  }
}

export default GlobeComponent;
