import { html as uHtml } from 'uhtml';
import GirafeHTMLElement from '../../../base/GirafeHTMLElement';
import { Bookmark } from '../Bookmark';
import NavHelperComponent from '../navhelper/component';

class NavBookmarksComponent extends GirafeHTMLElement {
  template = () => { return uHtml`<style>
.content {
  display: flex;
}

input {
  border: solid 1px #ccc;
  border-radius: 0.25rem;
  background-color: #fff;
  padding-left: 1rem;
  color: #000;
  font-size: 0.9rem;
}

button {
  border: none;
  border-right: solid 1px var(--bkg-color);
  width: 2.3rem;
  height: 2.3rem;
  line-height: 2.3rem;
  background-color: #444;
  color: #fff;
  padding: 0;
  cursor: pointer;
  margin: 0;
  display: inline-block;
}

button:hover {
  background-color: #000;
}

</style>
<link rel="stylesheet" href="lib/fontawesome/css/all.min.css" />

<div class="content">
  <input
    id="name"
    type="text"
    placeholder="Enter name..."
    onkeypress="${(e: KeyboardEvent) => { if (e.key === 'Enter') this.saveBookmark(); }}" />

  <button tip="Add bookmark" onclick="${() => this.saveBookmark()}">
    <i class="fa-sm fa-solid fa-check"></i>
  </button>
</div>
`; }
  

  navHelper: NavHelperComponent;
  nameInput: HTMLInputElement | null = null;

  constructor(parent: NavHelperComponent) {
    super('navbookmarks');
    this.navHelper = parent;
  }

  render() {
    super.render();

    this.nameInput = this.shadow.getElementById('name') as HTMLInputElement;
    this.nameInput.focus();
  }

  saveBookmark() {
    if (this.nameInput) {
      try {
        const bookmark = new Bookmark(this.nameInput.value, this.state.position);
        this.navHelper.addBookmark(bookmark);
        // Reset value
        this.nameInput.value = '';
      } catch (e) {
        alert(e);
      }
    }
  }

  connectedCallback() {
    this.loadConfig().then(() => {
      this.render();
      super.girafeTranslate();
    });
  }
}

export default NavBookmarksComponent;
