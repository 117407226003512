import { html as uHtml } from 'uhtml';
import GirafeHTMLElement from '../../base/GirafeHTMLElement';
import { InfoBoxContent } from '../../tools/state/state';

class InfoboxComponent extends GirafeHTMLElement {
  template = () => { return uHtml`<style>
.content {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
}

.content div {
  position: relative;
  border: solid 1px black;
  width: 24rem;
  line-height: 1.5rem;
  flex: 1;
  margin: 5px;
  padding: 10px;
  color: var(--text-color);
}

.content div.info {
  background-color: var(--bkg-color);
}

.content div.warning {
  background-color: orange;
  color: #fff;
}

.content div.error {
  background-color: var(--error-color);
  color: #fff;
}

i {
  border: none;
  color: var(--text-color-grad2);
  padding: 0;
  margin: 0.5rem;
  position: absolute;
  top: 0;
  right: 0;
  background-color: var(--bkg-color);
  cursor: pointer;
}

span {
  word-wrap: break-word;
}

.content div.error i {
  background-color: var(--error-color);
  color: #fff;
}

.content div.warning i {
  background-color: orange;
  color: #fff;
}

</style>
<link rel="stylesheet" href="lib/fontawesome/css/all.min.css" />
<div class="content">
  ${(this.state.infobox.elements ?? []).map(info => uHtml`
  <div class="${info.type}">
    <span>${this.htmlUnsafe(info.text)}</span>
    <i class="fa fa-solid fa-xmark" tip="Close message" onclick="${() => this.closeMessage(info)}"></i>
  </div>
  `)}
</div>
`; }
  

  infos = [] as InfoBoxContent[];

  constructor() {
    super('infobox');
  }

  registerEvents() {
    this.stateManager.subscribe('infobox.elements', () => super.render());
  }

  closeMessage(info: InfoBoxContent) {
    this.state.infobox.elements.splice(
      this.state.infobox.elements.findIndex((el) => el.id === info.id),
      1
    );
  }

  connectedCallback() {
    this.loadConfig().then(() => {
      super.render();
      super.girafeTranslate();
      this.registerEvents();
    });
  }
}

export default InfoboxComponent;
