import { html as uHtml } from 'uhtml';
import GirafeHTMLElement from '../../base/GirafeHTMLElement';
import LayerWms from '../../models/layers/layerwms';
import WfsManager from '../../tools/wfsmanager';
import { LayerAttribute } from '../../models/serverwfs';
import FilterHelper from './tools/filterhelper';

export type XmlTypes =
  | 'string'
  | 'byte'
  | 'decimal'
  | 'int'
  | 'integer'
  | 'long'
  | 'negativeInteger'
  | 'nonNegativeInteger'
  | 'nonPositiveInteger'
  | 'positiveInteger'
  | 'short'
  | 'unsignedLong'
  | 'unsignedInt'
  | 'unsignedShort'
  | 'unsignedByte'
  | 'double'
  | 'date'
  | null;

export const xmlNumberTypesStrList: string[] = [
  'byte',
  'decimal',
  'int',
  'integer',
  'long',
  'negativeInteger',
  'nonNegativeInteger',
  'nonPositiveInteger',
  'positiveInteger',
  'short',
  'unsignedLong',
  'unsignedInt',
  'unsignedShort',
  'unsignedByte',
  'double'
];

export const xmlTypesStrList: string[] = xmlNumberTypesStrList.concat(['string', 'date']);

class QueryBuilderComponent extends GirafeHTMLElement {
  template = () => { return uHtml`<style>
.content {
  display: flex;
}

.hidden {
  display: none;
}

.loading i {
  margin: 0.8rem;
}

button {
  border: none;
  border-right: solid 1px var(--bkg-color);
  width: 2.3rem;
  height: 2.3rem;
  line-height: 2.3rem;
  background-color: #444;
  color: #fff;
  padding: 0;
  cursor: pointer;
  margin: 0;
  display: inline-block;
}

button:hover {
  background-color: #000;
}

/* Custom style for select */
.custom-select {
  min-width: 15rem;
  position: relative;
}

input,
select {
  border: solid 1px #ccc;
  border-radius: 0.25rem;
  background-color: #fff;
  padding-left: 1rem;
  color: #000;
  font-size: 0.9rem;
}

select {
  appearance: none;
  -webkit-appearance: none;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.custom-select::before,
.custom-select::after {
  content: '';
  position: absolute;
  right: 1rem;
  pointer-events: none;
}

.custom-select::after {
  border-left: 0.4rem solid transparent;
  border-right: 0.4rem solid transparent;
  border-top: 0.4rem solid black;
  top: 45%;
}

</style>
<link rel="stylesheet" href="lib/fontawesome/css/all.min.css" />

<div>
  <div class="${this.loading ? 'loading' : 'loading hidden'}">
    <i class="fa-xl fa-solid fa-circle-notch fa-spin"></i>
    Loading server configuration...
  </div>
  <div class="${this.loading ? 'content hidden' : 'content'}">
    <div class="custom-select">
      <select id="attribute" name="attribute" onchange="${() => this.attributeChanged()}">
        <option value="" disabled selected>Select attribute...</option>
        ${this.layerAttributes.map(attr => uHtml.for(attr)`
        <option i18n="${attr.name}" value="${attr.name}">${attr.name}</option>
        `)}
      </select>
    </div>

    <div class="custom-select">
      <select id="operator" name="operator" onchange="${() => this.operatorChanged()}">
        <option value="" disabled selected>Select operator...</option>
        <option value="eq" class="${this.isNumber || this.isString ? '' : 'hidden'}">equals</option>
        <option value="neq" class="${this.isNumber || this.isString ? '' : 'hidden'}">does not equal</option>
        <option value="like" class="${!this.isString ? 'hidden' : ''}">contains</option>
        <option value="nlike" class="${!this.isString ? 'hidden' : ''}">does not contain</option>
        <option value="gt" class="${!this.isNumber ? 'hidden' : ''}">greater than</option>
        <option value="gte" class="${!this.isNumber ? 'hidden' : ''}">greater than or equal to</option>
        <option value="lt" class="${!this.isNumber ? 'hidden' : ''}">less than</option>
        <option value="lte" class="${!this.isNumber ? 'hidden' : ''}">less than or equal to</option>
        <option value="nul" class="${this.isNumber || this.isString ? '' : 'hidden'}">is blank</option>
        <option value="nnul" class="${this.isNumber || this.isString ? '' : 'hidden'}">is not blank</option>
      </select>
    </div>

    <input id="val" type="text" class="${this.showVal ? '' : 'hidden'}" placeholder="Enter value..." />

    <button tip="Apply filter" onclick="${() => this.filter()}">
      <i class="fa-sm fa-solid fa-filter"></i>
    </button>

    <button tip="Remove filter" onclick="${() => this.removeFilter()}">
      <i class="fa-sm fa-solid fa-trash"></i>
    </button>
  </div>
</div>
`; }
  

  loading: boolean = true;
  layer: LayerWms;
  layerAttributes: LayerAttribute[] = [];
  currentAttributeType: XmlTypes = null;

  showVal: boolean = false;

  constructor(layer: LayerWms) {
    super('querybuilder');
    this.layer = layer;
  }

  render() {
    if (!this.layer.urlWfs) {
      throw new Error('No WFS URL found. Please verify the Layer type.');
    }

    super.render();

    WfsManager.getInstance()
      .getServerWfs(this.layer.urlWfs)
      .then((serverWfs) => {
        console.log(this.layer.name);
        console.log(serverWfs);
        this.layerAttributes = serverWfs.layers[this.layer.name];
        this.loading = false;
        super.render();
        super.girafeTranslate();
        this.activateTooltips(false, [800, 0], 'top-end');
      });
  }

  get isString() {
    // TODO REG: Manage the type date as a separate type
    return this.currentAttributeType === 'string' || this.currentAttributeType === 'date';
  }

  get isNumber() {
    return xmlNumberTypesStrList.includes(this.currentAttributeType ?? '');
  }

  attributeChanged() {
    const attributeSelect = this.shadow.getElementById('attribute') as HTMLSelectElement;
    const layerAttribute = this.layerAttributes.find((attr) => (attr.name = attributeSelect.value));
    if (!layerAttribute) {
      throw new Error('Why is this object null ? This should never happen...');
    }

    this.currentAttributeType = layerAttribute.type;
    super.render();
  }

  operatorChanged() {
    const operatorSelect = this.shadow.getElementById('operator') as HTMLSelectElement;
    this.showVal = operatorSelect.value !== 'nul' && operatorSelect.value !== 'nnul';
    super.render();
  }

  filter() {
    const attributeSelect = this.shadow.getElementById('attribute') as HTMLSelectElement;
    const operatorSelect = this.shadow.getElementById('operator') as HTMLSelectElement;
    const val = this.shadow.getElementById('val') as HTMLInputElement;

    this.layer.filter = FilterHelper.getFilter(
      operatorSelect.value,
      attributeSelect.value,
      this.currentAttributeType,
      val.value
    );
    console.log(this.layer.filter);
  }

  removeFilter() {
    const attributeSelect = this.shadow.getElementById('attribute') as HTMLSelectElement;
    const operatorSelect = this.shadow.getElementById('operator') as HTMLSelectElement;
    const val = this.shadow.getElementById('val') as HTMLInputElement;

    this.layer.filter = '';
    attributeSelect.value = '';
    operatorSelect.value = '';
    val.value = '';
    this.currentAttributeType = null;
    this.showVal = false;
    super.render();
  }

  connectedCallback() {
    this.loadConfig().then(() => {
      this.render();
    });
  }
}

export default QueryBuilderComponent;
