import { html as uHtml } from 'uhtml';
import GirafeHTMLElement from '../../base/GirafeHTMLElement';
import Theme from '../../models/theme';
import MapManager from '../../tools/state/mapManager';

class ThemeComponent extends GirafeHTMLElement {
  template = () => { return uHtml`<style>
#themes {
  display: none;
  width: 50rem;
  margin-top: 0;
  overflow-y: auto;
  max-height: 70vh;
  scrollbar-width: thin;
  background: var(--bkg-color);
}

button {
  border: none;
  width: 4rem;
  height: 4.6rem;
  background-color: #444;
  color: #fff;
  padding: 0.5rem;
  cursor: pointer;
}

div.hidden {
  display: none;
}

button:hover {
  background-color: #000;
}

ul {
  border: solid 1px #444;
  border-radius: 3px;
  padding: 1rem;
  background-color: var(--bkg-color);
}

ul div {
  display: inline-block;
  width: 9rem;
  cursor: pointer;
  margin: 0.5rem;
}

ul div img {
  width: 9rem;
}

ul div span {
  display: inline-block;
  width: 9rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

</style>
<link rel="stylesheet" href="lib/fontawesome/css/all.min.css" />

<button title="Theme Selection" onclick="${() => this.toggleThemesList()}" onblur="${() => this.onBlur()}">
  <div class="${(this.state.loading ? 'hidden' : '')}">
    <i class="fa-2x fa-solid fa-layer-group"></i>
  </div>
  <div class="${(this.state.loading ? '' : 'hidden')}">
    <i class="fa-2x fa-solid fa-circle-notch fa-spin"></i>
  </div>
</button>
<ul id="themes">
  ${Object.values(this.state.themes ?? {}).map(theme => uHtml.for(theme)`
  <div onmousedown="${() => this.ignoreBlur()}" onclick="${() => this.onThemeChanged(theme)}">
    <img alt="${'Icon for ' + theme.name}" src="${theme.icon}" />
    <span i18n="${theme.name}">${theme.name}</span>
  </div>
  `)}
</ul>
`; }
  

  #themesList?: HTMLUListElement;
  #ignoreBlur = false;
  private readonly mapManager: MapManager;

  constructor() {
    super('themes');
    this.mapManager = MapManager.getInstance();
  }

  get themesList() {
    if (!this.#themesList) {
      throw new Error('You called themesList before render');
    }
    return this.#themesList;
  }

  render() {
    super.render();

    this.#themesList = this.shadow.querySelector('#themes')!;
    this.toggleThemesList(false);
  }

  registerEvents() {
    this.stateManager.subscribe('loading', () => super.render());
    this.stateManager.subscribe('themes', () => {
      super.render();
      super.girafeTranslate();
    });
  }

  onBlur() {
    if (!this.#ignoreBlur) {
      this.toggleThemesList(false);
    }
  }

  toggleThemesList(forceDisplay: null | boolean = null) {
    if (forceDisplay === true) {
      this.themesList.style.display = 'block';
    } else if (forceDisplay === false) {
      this.themesList.style.display = 'none';
    } else if (this.themesList.style.display === 'none') {
      this.themesList.style.display = 'block';
    } else {
      this.themesList.style.display = 'none';
    }
  }

  ignoreBlur() {
    this.#ignoreBlur = true;
  }

  onThemeChanged(theme: Theme) {
    this.state.selectedTheme = theme;
    this.toggleThemesList(false);
    this.#ignoreBlur = false;

    if (theme.location != null || theme.zoom != null) {
      const view = this.mapManager.getMap().getView();
      view.animate({
        center: theme.location ?? view.getCenter(),
        zoom: theme.zoom ?? view.getZoom(),
        duration: 1000
      });
    }
  }

  connectedCallback() {
    this.loadConfig().then(() => {
      this.render();
      this.registerEvents();
    });
  }
}

export default ThemeComponent;
