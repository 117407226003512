import { html as uHtml } from 'uhtml';
import GeoEvents from '../../models/events';
import GirafeResizableElement from '../../base/GirafeResizableElement';
import Picker from 'vanilla-picker/csp';

class RedliningComponent extends GirafeResizableElement {
  template = () => { return uHtml`<style>
:host,
#panel {
  min-width: 18rem;
}

#panel {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: visible;
  display: none;
}

#gutter {
  border: none;
  width: 6px;
  height: 100%;
  background: var(--bkg-color);
  position: absolute;
  top: 0;
  left: 0;
  cursor: col-resize;
}

#close {
  border: solid 1px var(--text-color-grad1);
  border-right: none;
  color: var(--text-color-grad1);
  padding: 0.7rem 0 0.7rem 0.5rem;
  border-radius: 10px 0 0 10px;
  width: 0.8rem;
  height: 2rem;
  line-height: 2rem;
  position: fixed;
  top: 5.8rem;
  right: 20rem;
  background-color: var(--bkg-color);
  cursor: pointer;
}

#close i:before {
  content: '\uf00d';
}

#close:hover {
  border-color: var(--text-color);
  color: var(--text-color);
}

#content {
  position: absolute;
  top: 0;
  left: 6px;
  right: 0;
  bottom: 0;
  background: var(--bkg-color);
  padding: 1.5rem;
  margin: 0;
  overflow: visible;
}

#content button {
  border: solid 1px var(--bkg-color);
  color: var(--text-color-grad2);
  height: 2.4rem;
  width: 2.4rem;
  text-align: left;
  padding: 0;
  padding-left: 0.5rem;
  background-color: var(--bkg-color);
  cursor: pointer;
}

#content button:hover {
  border: solid 1px var(--text-color-grad2);
}

#content button.selected {
  border: solid 1px var(--text-color-grad2);
  background-color: var(--text-color-grad2);
  color: var(--bkg-color);
}

#drawingList {
  min-height: 5rem;
  background-color: var(--bkg-color);
  padding-top: 2rem;
  margin-right: 1rem;
}

#drawingList div.girafe {
  display: inline-block;
  position: relative;
  padding: 0.5rem;
  margin-top: 0.1rem;
  margin-right: 0.1rem;
  border: solid 1px var(--text-color);
  text-align: left;
  width: 100%;
  max-width: 16rem;
  min-width: 13rem;
  color: var(--text-color-grad2);
}

#drawingList div.girafe:hover {
  color: var(--text-color);
}

#drawingList input.name {
  display: block;
  border: none;
}

#drawingList i {
  position: relative;
  cursor: pointer;
  margin-right: 1rem;
  display: inline;
}

#drawingList .text-opts {
  position: absolute;
  right: 0;
  top: 0.5rem;
}

input.slider {
  position: relative;
  width: 6rem;
  display: inline-block;
  vertical-align: middle;
  margin-right: 1rem;
}

</style>
<link rel="stylesheet" href="lib/font-gis/font-gis.css" />
<link rel="stylesheet" href="lib/fontawesome/css/all.min.css" />
<link rel="stylesheet" href="lib/vanilla-picker/vanilla-picker.csp.css" />

<div id="panel">
  <div id="gutter"></div>
  <div id="close">
    <i class="fa-solid"></i>
  </div>
  <div id="content">
    <button id="disable" tip="Disable drawing" class="selected">
      <i class="fg-arrow-o fg-lg"></i>
    </button>
    <button id="point" tip="Point">
      <i class="fg-point fg-lg"></i>
    </button>
    <button id="line" tip="Line">
      <i class="fg-polyline-pt fg-lg"></i>
    </button>
    <button id="polygon" tip="Polygon">
      <i class="fg-polygon-pt fg-lg"></i>
    </button>
    <button id="square" tip="Square">
      <i class="fg-square-pt fg-lg"></i>
    </button>
    <button id="rectangle" tip="Rectangle">
      <i class="fg-rectangle-pt fg-lg"></i>
    </button>
    <button id="circle" tip="Circle">
      <i class="fg-circle-o fg-lg"></i>
    </button>
    <button id="freeline" tip="Freehand line">
      <i class="fg-polyline fg-lg"></i>
    </button>
    <button id="freepolygon" tip="Freehand polygon">
      <i class="fg-polygon-o fg-lg"></i>
    </button>
    <button id="undo" tip="Undo">
      <i class="fg-help-larrow fg-lg"></i>
    </button>
    <div id="drawingList"></div>
  </div>
</div>
`; }
  

  panel = null;
  disableButton = null;
  pointButton = null;
  lineButton = null;
  squareButton = null;
  rectangleButton = null;
  polygonButton = null;
  circleButton = null;
  freelineButton = null;
  freepolygonButton = null;
  undoButton = null;

  toolSelected = null;

  drawingList = null;

  constructor() {
    super('redlining');
  }

  render() {
    super.render();

    // Bar is hidden per default
    this.panel = this.shadow.querySelector('#panel');
    this.panel.style.display = 'none';

    this.disableButton = this.shadow.querySelector('#disable');
    this.pointButton = this.shadow.querySelector('#point');
    this.lineButton = this.shadow.querySelector('#line');
    this.squareButton = this.shadow.querySelector('#square');
    this.rectangleButton = this.shadow.querySelector('#rectangle');
    this.polygonButton = this.shadow.querySelector('#polygon');
    this.circleButton = this.shadow.querySelector('#circle');
    this.freelineButton = this.shadow.querySelector('#freeline');
    this.freepolygonButton = this.shadow.querySelector('#freepolygon');
    this.undoButton = this.shadow.querySelector('#undo');

    this.drawingList = this.shadow.querySelector('#drawingList');

    this.toolSelected = this.disableButton;

    this.activateTooltips(false, [800, 0], 'top-end');
  }

  registerEvents() {
    this.stateManager.subscribe('interface.redliningPanelVisible', (oldValue, newValue) => this.togglePanel(newValue));
    this.stateManager.subscribe('redlining.features', (oldFeatures, newFeatures) =>
      this.onFeaturesChanged(oldFeatures, newFeatures)
    );

    this.disableButton.addEventListener('click', (e) => this.deactivateDraw(e));
    this.pointButton.addEventListener('click', (e) => this.activateDraw(e, 'Point'));
    this.lineButton.addEventListener('click', (e) => this.activateDraw(e, 'LineString'));
    this.squareButton.addEventListener('click', (e) => this.activateDraw(e, 'Square'));
    this.rectangleButton.addEventListener('click', (e) => this.activateDraw(e, 'Rectangle'));
    this.polygonButton.addEventListener('click', (e) => this.activateDraw(e, 'Polygon'));
    this.circleButton.addEventListener('click', (e) => this.activateDraw(e, 'Circle'));
    this.freelineButton.addEventListener('click', (e) => this.activateDraw(e, 'Freeline'));
    this.freepolygonButton.addEventListener('click', (e) => this.activateDraw(e, 'Freepolygon'));
    this.undoButton.addEventListener('click', () => this.messageManager.sendMessage({ action: GeoEvents.undoDraw }));
  }

  activateDraw(e, tool) {
    if (this.toolSelected !== null) {
      this.toolSelected.className = '';
    }
    this.toolSelected = e.target.parentElement;
    this.toolSelected.className = 'selected';

    this.state.redlining.activeTool = tool;
  }

  deactivateDraw(e) {
    if (this.toolSelected !== null) {
      this.toolSelected.className = '';
    }
    this.toolSelected = e.target.parentElement;
    this.toolSelected.className = 'selected';

    this.state.redlining.activeTool = null;
  }

  connectedCallback() {
    this.loadConfig().then(() => {
      this.render();
      super.girafeTranslate();
      this.registerEvents();
    });
  }

  closePanel() {
    this.state.interface.redliningPanelVisible = false;
  }

  togglePanel(visible) {
    if (visible) {
      this.panel.style.display = 'block';
      this.panel.getRootNode().host.style.display = 'block';
    } else {
      this.panel.style.display = 'none';
      this.panel.getRootNode().host.style.display = 'none';
    }
  }

  onFeaturesChanged(oldFeatures, newFeatures) {
    const deletedFeatures = oldFeatures.filter(
      (oldFeature) => !newFeatures.find((newFeature) => newFeature.id === oldFeature.id)
    );
    const addedFeatures = newFeatures.filter(
      (newFeature) => !oldFeatures.find((oldFeature) => oldFeature.id === newFeature.id)
    );

    deletedFeatures.forEach((feature) => {
      this.removeFeatureFromList(feature);
    });

    addedFeatures.forEach((feature) => {
      this.addFeatureToList(feature);
    });
  }

  addFeatureToList(feature) {
    const elementId = 'f-' + feature.id;
    const container = document.createElement('div');
    container.className = 'girafe';
    container.id = elementId;

    // Label
    const nameinput = document.createElement('input');
    nameinput.type = 'text';
    nameinput.value = feature.name;
    nameinput.className = 'name';
    nameinput.oninput = (e) => {
      const changedFeature = this.state.redlining.features.find((f) => f.id === feature.id);
      changedFeature.name = e.target.value;
    };
    container.appendChild(nameinput);

    // Label options
    const textdiv = document.createElement('div');
    textdiv.className = 'text-opts';

    const textminus = document.createElement('i');
    textminus.className = 'fa-solid fa-minus';
    textminus.onclick = () => {
      const changedFeature = this.state.redlining.features.find((f) => f.id === feature.id);
      changedFeature.textSize = changedFeature.textSize - 1;
    };
    textdiv.appendChild(textminus);

    const textplus = document.createElement('i');
    textplus.className = 'fa-solid fa-plus';
    textplus.onclick = () => {
      const changedFeature = this.state.redlining.features.find((f) => f.id === feature.id);
      changedFeature.textSize = changedFeature.textSize + 1;
    };
    textdiv.appendChild(textplus);

    container.appendChild(textdiv);

    // Color Selector (Fill)
    const fill = document.createElement('i');
    fill.className = 'fa-solid fa-paint-roller';
    container.appendChild(fill);
    const fillColor = this.isNullOrUndefined(feature.fillColor)
      ? this.configManager.Config.redlining.defaultFillColor
      : feature.fillColor;
    const fillPicker = new Picker({ parent: fill, color: fillColor, popup: 'left' });
    // Message when color changed
    fillPicker.onChange = (color) => {
      const changedFeature = this.state.redlining.features.find((f) => f.id === feature.id);
      changedFeature.fillColor = color.hex;
    };
    fillPicker.onDone = (color) => {
      const changedFeature = this.state.redlining.features.find((f) => f.id === feature.id);
      changedFeature.fillColor = color.hex;
    };

    // Color Selector (Stroke)
    const stroke = document.createElement('i');
    stroke.className = 'fa-solid fa-paintbrush';
    container.appendChild(stroke);
    const strokeColor = this.isNullOrUndefined(feature.strokeColor)
      ? this.configManager.Config.redlining.defaultStrokeColor
      : feature.fillColor;
    const strokePicker = new Picker({ parent: stroke, color: strokeColor, popup: 'left' });
    // Message when color changed
    strokePicker.onChange = (color) => {
      const changedFeature = this.state.redlining.features.find((f) => f.id === feature.id);
      changedFeature.strokeColor = color.hex;
    };
    strokePicker.onDone = (color) => {
      const changedFeature = this.state.redlining.features.find((f) => f.id === feature.id);
      changedFeature.strokeColor = color.hex;
    };

    // StrokeWidth slider
    const slider = document.createElement('input');
    slider.type = 'range';
    slider.className = 'slider';
    slider.min = 0;
    slider.max = 10;
    slider.value = this.isNullOrUndefined(feature.strokeWidth)
      ? this.configManager.Config.redlining.defaultStrokeWidth
      : feature.strokeWidth;
    slider.oninput = (e) => {
      const changedFeature = this.state.redlining.features.find((f) => f.id === feature.id);
      changedFeature.strokeWidth = e.target.value;
    };
    container.appendChild(slider);

    // Trash
    const trash = document.createElement('i');
    trash.className = 'fa-solid fa-trash';
    trash.onclick = (e) => this.deleteFeature(feature);
    container.appendChild(trash);

    this.drawingList.appendChild(container);
  }

  removeFeatureFromList(feature) {
    const elementId = 'f-' + feature.id;
    const divToRemove = this.shadow.getElementById(elementId);
    divToRemove.remove();
  }

  deleteFeature(feature) {
    if (confirm('Do you want to delete this feature?')) {
      this.state.redlining.features = this.state.redlining.features.filter((f) => {
        return f.id != feature.id;
      });
    }
  }
}

export default RedliningComponent;
