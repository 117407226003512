import { html as uHtml } from 'uhtml';
import { buffer, getWidth, getHeight, Extent } from 'ol/extent';

import GirafeHTMLElement from '../../base/GirafeHTMLElement';
import GeoEvents from '../../models/events';
import SearchResult from '../../models/searchresult';
import ThemesManager from '../../tools/themesmanager';

class SearchComponent extends GirafeHTMLElement {
  template = () => { return uHtml`<style>
.hidden {
  display: none;
}

#searchbox {
  background-color: var(--bkg-color);
  border-right: none;
  height: 2.5rem;
  border-radius: 6px;
  border: none;
  width: 100%;
  display: flex;
  flex-direction: row;
  box-shadow: var(--bx-shdw);
}

#search {
  font-size: 1rem;
  outline: none;
  margin: 0 0 0 0.5rem;
  padding-left: 0.8rem;
  border: 0;
  height: 2.2rem;
  width: auto;
  flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  -webkit-flex: 1 1 auto;
  -webkit-appearance: none;
  background: transparent;
  color: var(--text-color);
}

.search-icon {
  display: inline-block;
  width: 20px;
  height: 25px;
  color: var(--text-color);
  background-position: -121px 1px;
  padding: 9px 10px;
  background-repeat: no-repeat;
  background-clip: content-box;
  background-origin: content-box;
  -moz-background-clip: content-box;
  -moz-background-origin: content-box;
  -webkit-background-clip: content-box;
  -webkit-background-origin: content-box;
}

#results {
  z-index: 1000;
  margin-top: 0.1rem;
  margin-bottom: 0.5rem;
  position: absolute;
  top: 3.5rem;
  width: 36rem;
  max-height: 31rem;
  background-color: var(--bkg-color);
  overflow-x: hidden;
  scrollbar-width: thin;
}

.result,
.title {
  display: inline-block;
  padding: 0.3rem 1rem;
  width: Calc(36rem - 2rem);
  border-top: solid 1px #ccc;
  line-height: 1.3rem;
}

.title {
  background-color: #555;
  color: var(--text-color);
  font-weight: 600;
  line-height: 2.5rem;
  font-size: 1.2rem;
}

.result {
  cursor: pointer;
}

.title i {
  margin-right: 1rem;
}

.result:hover {
  background-color: #aaa;
  color: var(--text-color);
}

.active {
  background-color: #aaa !important;
  color: var(--text-color);
}

@media screen and (max-width: 1000px) {
  #searchbox {
    border-radius: 0;
    border: solid 1px #666;
    width: 100%;
    display: flex;
    flex-direction: row;
    box-shadow: none;
  }

  #results {
    top: 7rem;
    left: 2rem;
  }
}

</style>
<link rel="stylesheet" href="lib/fontawesome/css/all.min.css" />

<div id="searchbox">
  <input
    id="search"
    length="20"
    maxlength="1000"
    autocomplete="off"
    autocorrect="off"
    i18n="Search"
    placeholder="Search..."
    oninput="${(e:Event) => this.doSearch(e)}"
    onfocusin="${() => this.onFocusIn()}"
    onfocusout="${() => this.onFocusOut()}"
    onkeydown="${(e:KeyboardEvent) => this.onKeyDown(e)}" />

  <a class="search-icon" href="#" title="Geogirafe search">
    <i class="fa-solid fa-magnifying-glass"></i>
  </a>

  <div
    id="results"
    class="${Object.keys(this.groupedResults).length === 0 || this.forceHide ? 'hidden' : ''}"
    onmousemove="${() => this.onMouseMove()}">
    ${Object.keys(this.groupedResults).map(group => uHtml`
    <div class="title"><i class="${this.getIconClassName(group)}"></i><span>${group}</span></div>
    ${this.groupedResults[group].map(result => uHtml`
    <div class="result" onmousedown="${() => this.ignoreBlur()}" onclick="${() => this.onSelect(result)}">
      <span>${result.properties?.label}</span>
    </div>
    `)} `)}
  </div>
</div>
`; }
  

  themeManager: ThemesManager;

  #ignoreBlur = false;
  groupedResults: Record<string, SearchResult[]> = {};
  forceHide: boolean = true;

  searchTermPlaceholder = '###SEARCHTERM###';
  searchLangPlaceholder = '###SEARCHLANG###';

  selectedResult: HTMLElement | null = null;
  selectedCntr: number = 0;

  bgClr = 'rgba(255, 255, 255, 0.1)';

  constructor() {
    super('search');
    this.themeManager = ThemesManager.getInstance();
  }

  registerEvents() {
    this.stateManager.subscribe('interface.darkFrontendMode', () => this.onChangeDarkFrontendMode());
  }

  onChangeDarkFrontendMode() {
    this.bgClr = this.state.interface.darkFrontendMode ? 'rgba(34, 34, 34, 1)' : 'rgba(255, 255, 255, 1)';
  }

  ignoreBlur() {
    this.#ignoreBlur = true;
  }

  onFocusIn() {
    this.forceHide = false;
    super.render();
  }

  onFocusOut() {
    if (!this.#ignoreBlur) {
      this.forceHide = true;
      super.render();
    }
    this.#ignoreBlur = false;
  }

  connectedCallback() {
    this.loadConfig().then(() => {
      this.registerEvents();
      super.render();
      super.girafeTranslate();
    });
  }

  clearSearch(purge: boolean = false) {
    if (purge) {
      const target = this.shadowRoot?.getElementById('search') as HTMLInputElement;
      if (target) {
        target.value = '';
      }
    }
    this.forceHide = false;
    this.groupedResults = {};
    this.selectedCntr = 0;
    this.selectedResult = null;
    super.render();
  }

  async doSearch(e: Event) {
    const target = e.target as HTMLInputElement;
    if (target) {
      const term: string = target.value;
      this.clearSearch();
      if (term.length > 0) {
        const url = this.configManager.Config.search.url
          .replace(this.searchTermPlaceholder, term)
          .replace(this.searchLangPlaceholder, this.state.language!);
        const response = await fetch(url);
        const data = await response.json();
        this.displayResults(data);
      }
      this.selectedResult = null;
      this.selectedCntr = 0;
    }
  }

  displayResults(results: { type: string; features: SearchResult[] }) {
    // First, group the results
    results.features.forEach((result) => {
      const type = result.properties ? result.properties.layer_name : 'ERROR: Missing type in the search result';

      let resultList: SearchResult[];
      if (type in this.groupedResults) {
        resultList = this.groupedResults[type];
      } else {
        resultList = [];
        this.groupedResults[type] = resultList;
      }

      resultList.push(result);
    });

    // And then rerender the results
    super.render();
  }

  getIconClassName(type: string) {
    // TODO: Do not hardcode values here
    switch (type) {
      case 'Adresse':
        return 'fa-solid fa-location-dot';
      case 'Basel Info (BI)':
        return 'fa-solid fa-map-location-dot';
      case 'Baumnummer öffentlicher Baumkataster':
        return 'fa-solid fa-tree';
      case 'Entsorgungsstellen':
        return 'fa-solid fa-recycle';
      case 'Haltestelle öffentlicher Verkehr':
        return 'fa-solid fa-train-subway';
      case 'Group':
        return 'fa-solid fa-layer-group';
      case 'Layer':
        return 'fa-solid fa-map';
      default:
        return 'fa-solid fa-globe';
    }
  }

  onSelect(result: SearchResult) {
    this.#ignoreBlur = false;
    this.forceHide = true;
    super.render();

    if (result.bbox) {
      // Result with geometry
      this.zoomTo(result.bbox);
    } else if (result.properties?.actions[0].action === 'add_group') {
      const group = this.themeManager.findGroupByName(result.properties?.actions[0].data);
      if (!this.state.layers.layersList.includes(group)) {
        this.state.layers.layersList.push(group);
      }
    } else if (result.properties?.actions[0].action === 'add_layer') {
      const layer = this.themeManager.findLayerByName(result.properties?.actions[0].data);
      if (!this.state.layers.layersList.includes(layer)) {
        this.state.layers.layersList.push(layer);
      }
    } else {
      console.warn('Unsupported result type');
    }
    this.onFocusOut();
  }

  selectResult() {
    // selecting the next search result with the keyboard
    const results = this.shadowRoot?.querySelectorAll('.result');

    const next = results![this.selectedCntr] as HTMLElement;
    if (next) {
      if (this.selectedResult) {
        this.selectedResult.classList.remove('active');
      }
      this.selectedResult = next;
      this.selectedResult.classList.add('active');
      this.selectedResult.scrollIntoView({ block: 'nearest' });
    }
  }

  onMouseMove() {
    // if the mouse moves, we activate the hover effect
    const results = this.shadowRoot?.querySelectorAll('.result');
    for (const result of results!) {
      result.classList.remove('active');
      const htmlResult = result as HTMLElement;
      htmlResult.style.removeProperty('background-color');
    }
  }

  removeHover() {
    // if the keyboard is used, we deactivate the hover effect
    const results = this.shadowRoot?.querySelectorAll('.result');
    for (const result of results!) {
      const htmlResult = result as HTMLElement;
      htmlResult.style.backgroundColor = this.bgClr;
    }
  }

  navigateToResult(e: KeyboardEvent) {
    // deactivate mouse hover effect
    this.removeHover();

    if (e.key === 'ArrowDown') {
      if (this.selectedResult) {
        this.selectedCntr += 1;
      }
      this.selectResult();
    }
    if (e.key === 'ArrowUp') {
      if (this.selectedResult) {
        this.selectedCntr -= 1;
      }
      this.selectResult();
    }
    // select search result
    if (e.key === 'Enter' && this.selectedResult !== null) {
      const results = [];

      for (const k in this.groupedResults) {
        results.push(...this.groupedResults[k]);
      }

      this.onSelect(results[this.selectedCntr]);
    }
  }

  onKeyDown(e: KeyboardEvent) {
    console.log(e.key);

    // clear search on escape
    if (e.key === 'Escape') {
      this.clearSearch(true);
    }

    // automatic re-open search results on enter
    else if (this.forceHide) {
      if (e.key === 'Enter') {
        this.onFocusIn();
      }
    }

    // navigate through search results
    else if (!this.forceHide) {
      this.navigateToResult(e);
    }
  }

  zoomTo(extent: Extent) {
    // We create a buffer around the extent from 50% of the width/height
    const bufferValue = Math.max((getWidth(extent) * 50) / 100, (getHeight(extent) * 50) / 100);
    const bufferedExtent = buffer(extent, bufferValue);

    this.messageManager.sendMessage({ action: GeoEvents.zoomToExtent, extent: bufferedExtent });
  }
}

export default SearchComponent;
