class LayerConsts {
  public static get LayerOsmId(): number {
    return -1;
  }

  public static get LayerSwisstopoVectorTilesId(): number {
    return -2;
  }
}

export default LayerConsts;
