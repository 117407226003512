import { html as uHtml } from 'uhtml';
import GirafeDraggableElement from '../../base/GirafeDraggableElement';
import TwitterLogo from './images/twitter.svg';
import FacebookLogo from './images/facebook.svg';
import LinkedInLogo from './images/linkedin.svg';
import MailLogo from './images/mail.svg';
import ShareManager from '../../tools/share/sharemanager';
import { IUrlShortener } from './tools/iurlshortener';
import LstuManager from './tools/lstumanager';
import GmfManager from './tools/gmfmanager';

class ShareComponent extends GirafeDraggableElement {
  template = () => { return uHtml`<style>
#draggable {
  border-radius: 1px;
  box-shadow: var(--bx-shdw);
}

#header {
  padding: 0.5rem;
  cursor: grabbing;
  z-index: 10;
  background: var(--bkg-color-grad2);
  color: var(--text-color);
  text-align: center;
}

#content {
  padding: 2rem;
  width: 24rem;
  height: 21rem;
  text-align: center;
}

.hidden {
  display: none;
}

#close {
  cursor: pointer;
  float: right;
}

.loading {
  margin-top: 8rem;
}

.link {
  display: flex;
  height: 2.3rem;
}

.link button {
  border: none;
  border-right: solid 1px var(--bkg-color);
  width: 2.3rem;
  height: 2.3rem;
  line-height: 2.3rem;
  background-color: #444;
  color: #fff;
  padding: 0;
  cursor: pointer;
  margin: 0;
  display: inline-block;
}

.qrcode {
  margin: auto;
  margin-top: 2rem;
  display: block;
  width: 10rem;
  opacity: 0.7;
}

.social {
  display: flex;
}

.social img {
  width: 3rem;
  margin: auto;
  margin-top: 3rem;
  cursor: pointer;
  opacity: 0.7;
}

.social img:hover {
  opacity: 1;
}

input {
  border-radius: 0.25rem;
  background-color: #fff;
  padding-left: 1rem;
  color: #000;
  font-size: 0.9rem;
  flex-grow: 1;
}

.error {
  order: 3;
  color: #b00;
}

</style>
<link rel="stylesheet" href="lib/fontawesome/css/all.min.css" />

<div id="draggable">
  <div id="header">
    Share this map with a link
    <div id="close">
      <i class="fa-solid fa-xmark"></i>
    </div>
  </div>

  <div id="content">
    <div class="${(this.loading ? '' : 'hidden')}">
      <i class="fa-4x fa-solid fa-circle-notch fa-spin loading"></i>
    </div>

    <div class="${(this.loading ? 'hidden' : '')}">
      <div class="link">
        <input id="link" type="text" value="${this.shareLink}" />
        <button tip="Add bookmark" onclick="${() => this.copyToClipboard()}">
          <i class="fa-sm fa-regular fa-copy"></i>
        </button>
      </div>

      <img alt="share-qrcode" class="${(this.qrCode ? 'qrcode' : 'hidden')}" src="${this.qrCode}" />
      <div class="${(this.qrCode ? 'hidden' : 'qrcode error')}">
        <i class="fa-solid fa-circle-exclamation tool error"></i>
        Error, can not fetch the shortener service. Please try again later.
      </div>

      <div class="social">
        <img alt="share-facebook" src="${this.facebookLogo}" onclick="${() => this.shareFacebook()}" />
        <img alt="share-twitter" src="${this.twitterLogo}" onclick="${() => this.shareTwitter()}" />
        <img alt="share-linkedin" src="${this.linkedInLogo}" onclick="${() => this.shareLinkedIn()}" />
        <img alt="share-mail" src="${this.mailLogo}" onclick="${() => this.shareMail()}" />
      </div>
    </div>
  </div>
</div>
`; }
  

  loading = true;

  shareLink?: string;
  qrCode?: string;
  twitterLogo: string = TwitterLogo;
  facebookLogo: string = FacebookLogo;
  linkedInLogo: string = LinkedInLogo;
  mailLogo: string = MailLogo;

  shareManager: ShareManager;
  urlShortener?: IUrlShortener;

  constructor() {
    super('share');

    this.shareManager = ShareManager.getInstance();
  }

  registerEvents() {
    this.stateManager.subscribe('interface.shareVisible', (_oldValue: boolean, newValue: boolean) =>
      this.togglePopup(newValue)
    );
  }

  initializeShortenerService() {
    // Initialize UrlShortener
    switch (this.configManager.Config.share.service) {
      case 'gmf':
        this.urlShortener = new GmfManager();
        break;
      case 'lstu':
        this.urlShortener = new LstuManager(this.configManager.Config.share.createUrl);
        break;
    }
  }

  render() {
    super.render();
    super.makeDraggable();
  }

  async togglePopup(visible: boolean) {
    if (this.urlShortener && visible) {
      this.loading = true;
      this.render();
      const base = window.location.href.split('#')[0];
      const hash = this.shareManager.getStateToShare();
      const longurl = `${base}#${hash}`;

      const response = await this.urlShortener.shortenUrl(longurl);
      this.shareLink = response.shorturl;
      this.qrCode = response.qrcode;
      this.loading = false;
      this.render();
    } else {
      this.renderEmpty();
    }
  }

  closeWindow() {
    this.state.interface.shareVisible = false;
  }

  shareFacebook() {
    window.open('https://www.facebook.com/sharer/sharer.php?u=' + this.shareLink, '_blank');
  }

  shareTwitter() {
    window.open('https://twitter.com/intent/tweet?url=' + this.shareLink, '_blank');
  }

  shareLinkedIn() {
    window.open('https://www.linkedin.com/shareArticle?url=' + this.shareLink, '_blank');
  }

  shareMail() {
    const subject = encodeURIComponent('Check out this link');
    const body = encodeURIComponent('I thought you might be interested in this link: ' + this.shareLink);
    window.location.href = 'mailto:?subject=' + subject + '&body=' + body;
  }

  copyToClipboard() {
    navigator.clipboard.writeText(this.shareLink!);
  }

  connectedCallback() {
    this.loadConfig().then(() => {
      this.render();
      this.girafeTranslate();
      this.initializeShortenerService();
      this.registerEvents();
    });
  }
}

export default ShareComponent;
