import { html as uHtml } from 'uhtml';
import GirafeHTMLElement from '../../base/GirafeHTMLElement';
import BaseLayer from '../../models/layers/baselayer';
import GroupLayer from '../../models/layers/grouplayer';
import LayerManager from '../../tools/layermanager';

class TreeViewGroupComponent extends GirafeHTMLElement {
  template = () => { return uHtml`<style>
.group {
  color: #999;
  border-top: solid 1px #ddd;
  line-height: 1.3rem;
  display: flex;
  flex-wrap: wrap;
}

.hidden {
  display: none;
}

.invisible {
  visibility: hidden;
}

.selectable {
  cursor: pointer;
}

.selectable:hover {
  color: var(--text-color);
}

.selected {
  cursor: pointer;
  color: var(--text-color);
}

i {
  width: 0.8rem;
  text-align: center;
  min-width: 0;
}

.expand,
.collapse {
  order: 1;
  padding-top: 0.15rem;
}

.circle-on,
.circle-off,
.circle-semi {
  order: 2;
  padding-top: 0.2rem;
  margin-left: 0.2rem;
  margin-right: 0.1rem;
  cursor: pointer;
}

.error {
  order: 3;
  color: var(--error-color);
}

.text {
  order: 4;
  display: inline-block;
  margin: 0.2rem;
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 0;
}

.delete {
  order: 5;
  padding-top: 0.45rem;
}

.sublayers {
  padding-left: 1rem;
  flex-basis: 100%;
  order: 10;
}

</style>
<link rel="stylesheet" href="lib/font-gis/font-gis.css" />
<link rel="stylesheet" href="lib/fontawesome/css/all.min.css" />
<link rel="stylesheet" href="lib/tippy.js/tippy.css" />
<link rel="stylesheet" href="lib/tippy.js/backdrop.css" />
<link rel="stylesheet" href="lib/tippy.js/border.css" />
<link rel="stylesheet" href="lib/tippy.js/svg-arrow.css" />

<div class="group">
  <!-- Caret -->
  <div class="${(this.group.isExpanded ? 'expand hidden' : 'expand')}" onclick="${() => this.group.isExpanded = true}">
    <i class="fa fa-caret-right selectable expand"></i>
  </div>
  <div
    class="${(this.group.isExpanded ? 'collapse' : 'collapse hidden')}"
    onclick="${() => this.group.isExpanded = false}">
    <i class="fa fa-caret-down selectable expand"></i>
  </div>

  <!-- Bullet or Checkbox -->
  <div
    class="${this.group.activeState === 'off' ? 'circle-off selectable' : 'circle-off hidden'}"
    onclick="${() => this.toggle('on')}">
    <i
      class="${this.configManager.Config.treeview.useCheckboxes ? 'fa-lg fa-regular fa-square selbox' : 'fa-xs fa-regular fa-circle selcircle'}"></i>
  </div>
  <div
    class="${this.group.activeState === 'on' ? 'circle-on selected' : 'circle-on hidden'}"
    onclick="${() => this.toggle('off')}">
    <i
      class="${this.configManager.Config.treeview.useCheckboxes ? 'fa-lg fa-solid fa-square-check selbox' : 'fa-xs fa-solid fa-circle selcircle'}"></i>
  </div>
  <div
    class="${this.group.activeState === 'semi' ? 'circle-semi selected' : 'circle-semi hidden'}"
    onclick="${() => this.toggle('on')}">
    <i
      class="${this.configManager.Config.treeview.useCheckboxes ? 'fa-lg fa-regular fa-square-check selbox' : 'fa-xs fa-solid fa-circle-half-stroke selcircle'}"></i>
  </div>

  <!-- Error icon -->
  <i
    class="${this.group.hasError ? 'fa-solid fa-circle-exclamation tool error' : 'error hidden'}"
    tip="${this.group.errorMessage}"
    tip-theme="error"></i>

  <!-- Label -->
  <span
    i18n="${this.group.name}"
    class="${this.group.activeState === 'on' || this.group.activeState === 'semi' ? 'text selected' : 'text selectable'}"
    onclick="${() => this.toggle()}"
    >${this.group.name}</span
  >

  <i
    class="fa fa-solid fa-xmark tool delete selectable"
    tip="Remove this group"
    onclick="${() => this.deleteGroup()}"></i>

  <!-- Sub layers -->
  <div class="${(this.group.isExpanded ? 'sublayers' : 'sublayers hidden')}">
    ${(this.group.children ?? []).map(layer => (layer instanceof GroupLayer) ? uHtml`<girafe-tree-view-group
      groupid="${layer.treeItemId}"></girafe-tree-view-group
    >` : uHtml`<girafe-tree-view-item layerid="${layer.treeItemId}"></girafe-tree-view-item>` )}
  </div>
</div>
`; }
  

  layerManager: LayerManager;

  group: GroupLayer;

  constructor(group: GroupLayer) {
    super('treeviewgroup');

    this.layerManager = LayerManager.getInstance();
    this.group = group;
  }

  render() {
    // If we come from an  html element, the layer was not defined in the constructor
    // And we have to set the layer using the id passed to the layerid attribute
    const groupId = this.getAttribute('groupid');
    if (groupId) {
      this.group = this.layerManager.getTreeItem(groupId) as GroupLayer;
    }
    super.render();
    this.activateTooltips(false, [800, 0], 'right');
  }

  registerEvents() {
    this.stateManager.subscribe(
      /layers\.layersList\..*\.isExpanded/,
      (_oldValue: boolean, _newValue: boolean, group: GroupLayer) => this.refreshRender(group)
    );
    this.stateManager.subscribe(
      /layers\.layersList\..*\.activeState/,
      (_oldValue: boolean, _newValue: boolean, group: GroupLayer) => this.refreshRender(group)
    );
    this.stateManager.subscribe(
      /layers\.layersList\..*\.children/,
      (oldChildren: BaseLayer[], newChildren: BaseLayer[], group: GroupLayer) =>
        this.onChildrenListChanged(oldChildren, newChildren, group)
    );
  }

  refreshRender(group: GroupLayer) {
    if (group === this.group) {
      super.render();
    }
  }

  onChildrenListChanged(oldChildren: BaseLayer[], newChildren: BaseLayer[], group: GroupLayer) {
    this.refreshRender(group);
    // If we added a new group to the list of layers
    // Then we activate the layers that should be activated by default
    const addedLayers = newChildren.filter(
      (newChild) => !oldChildren.find((oldChild) => oldChild.treeItemId === newChild.treeItemId)
    );
    this.activateDefaultLayers(addedLayers);
  }

  activateDefaultLayers(layers: BaseLayer[]) {
    for (const layer of layers) {
      this.layerManager.activateIfDefaultChecked(layer);
      if (layer instanceof GroupLayer) {
        this.activateDefaultLayers(layer.children);
      }
    }
  }

  toggle(state?: 'on' | 'off' | 'semi') {
    this.layerManager.toggleGroup(this.group, state);
  }

  connectedCallback() {
    this.loadConfig().then(() => {
      this.render();
      super.girafeTranslate();
      this.registerEvents();
    });
  }

  deactivateGroup(layer: BaseLayer) {
    layer.activeState = 'off';
    if (layer instanceof GroupLayer) {
      for (const child of layer.children) {
        this.deactivateGroup(child);
      }
    }
  }

  deleteGroup() {
    this.deactivateGroup(this.group);
    const index = this.state.layers.layersList.findIndex((g) => g.id === this.group.id);
    if (index >= 0) {
      this.state.layers.layersList.splice(index, 1);
    } else {
      // TODO REG : manage subgroup deletion
      console.log('cannot delete this group. Probably a subgroup, this is not managed yet.');
    }
  }
}

export default TreeViewGroupComponent;
