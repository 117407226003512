import { html as uHtml } from 'uhtml';
import GirafeResizableElement from '../../base/GirafeResizableElement';
import BaseLayer from '../../models/layers/baselayer';
import GroupLayer from '../../models/layers/grouplayer';
import LayerManager from '../../tools/layermanager';
import LayerWms from '../../models/layers/layerwms';

class TreeViewComponent extends GirafeResizableElement {
  template = () => { return uHtml`<style>
:host {
  min-width: 18rem;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background: #999;
}

.hidden {
  display: none;
}

.panel {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

.gutter {
  border: none;
  width: 5px;
  height: 100%;
  background: var(--bkg-color);
  position: absolute;
  top: 0;
  right: 0;
  cursor: col-resize;
}

.hide {
  border: solid 1px #999;
  border-left: none;
  color: var(--text-color-grad2);
  padding: 0.7rem 0.5rem 0.7rem 0;
  border-radius: 0 10px 10px 0;
  width: 0.8rem;
  height: 2rem;
  line-height: 2rem;
  position: fixed;
  top: 5.8rem;
  left: 25rem;
  background-color: var(--bkg-color);
  cursor: pointer;
}

.hide i:before {
  content: '\uf104';
}

.hide.closed {
  left: 5px !important;
}

.hide.closed i:before {
  content: '\uf105';
}

.hide:hover {
  border-color: #000;
  color: var(--text-color);
}

.menu {
  border-top: solid 1px #bbb;
  background-color: var(--bkg-color);
  height: 2.3rem;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 6px;
  z-index: 1;
  font-size: 0;
}

.treeview-list {
  position: absolute;
  top: 0;
  left: 0;
  right: 5px;
  bottom: 2.3rem;
  background: var(--bkg-color);
  padding-left: 1rem;
  padding-top: 1rem;
  padding-bottom: 1.5rem;
  padding-right: 0.7rem;
  margin: 0;
  margin-top: 0.5rem;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
}

.menu i {
  margin-top: 1rem;
  color: var(--text-color-grad1);
  margin-right: 0.2rem;
  width: 3rem;
}

#menu i.selected {
  color: var(--text-color);
}

.selectable {
  cursor: pointer;
}

.selectable:hover {
  color: #000;
}

girafe-basemap {
  position: absolute;
  right: 0;
  top: 0;
}

@media screen and (max-width: 1000px) {
  .panel {
    width: 18rem;
  }

  .hide {
    left: 18rem;
    top: 8.3rem;
  }
}

</style>
<link rel="stylesheet" href="lib/font-gis/font-gis.css" />
<link rel="stylesheet" href="lib/fontawesome/css/all.min.css" />
<link rel="stylesheet" href="lib/tippy.js/tippy.css" />
<link rel="stylesheet" href="lib/tippy.js/backdrop.css" />
<link rel="stylesheet" href="lib/tippy.js/border.css" />
<link rel="stylesheet" href="lib/tippy.js/svg-arrow.css" />

<div id="panel" class="panel">
  <div class="menu">
    <girafe-button
      icon-style="${this.state.treeview.advanced ? 'fa-sm fa-solid fa-gear selectable' : 'fa-sm fa-solid fa-gears selectable'}"
      size="small"
      tip="Advanced options"
      tip-placement="top"
      class="options transparent"
      onclick="${() => this.state.treeview.advanced = !this.state.treeview.advanced}"></girafe-button>

    <girafe-button
      icon-style="${this.areAllLegendExpanded ? 'fa-sm fa-solid fa-bars selectable fa-rotate-270' : 'fa-sm fa-solid fa-bars selectable'}"
      size="small"
      tip="Toggle all legends"
      tip-placement="top"
      class="togglelegends transparent"
      onclick="${() => this.toggleAllLegends()}"></girafe-button>

    <girafe-button
      icon-style="${this.isAllExpanded ? 'fa-sm fa-solid fa-caret-right selectable' : 'fa-sm fa-solid fa-caret-down selectable'}"
      size="small"
      tip="Expand / Collapse all"
      tip-placement="top"
      class="expandall transparent"
      onclick="${() => this.expandAll()}"></girafe-button>

    <girafe-button
      icon-style="fa-sm fa-solid fa-trash selectable"
      size="small"
      tip="Remove all layers"
      tip-placement="top"
      class="delete transparent"
      onclick="${() => this.removeAll()}"></girafe-button>

    <girafe-button
      icon-style="fa-sm fa-solid fa-left-right selectable"
      size="small"
      tip="Hide Swiper"
      tip-placement="top"
      class="${this.isSwiperVisible ? 'swipe transparent' : 'swipe hidden'}"
      onclick="${() => this.hideSwipe()}"></girafe-button>

    <girafe-basemap tip="Select basemap"></girafe-basemap>
  </div>
  <div class="treeview-list">
    ${this.state.layers.layersList.map(layer => (layer instanceof GroupLayer) ? uHtml.for(layer)`<girafe-tree-view-group
      groupid="${layer.treeItemId}"></girafe-tree-view-group
    >` : uHtml`<girafe-tree-view-item layerid="${layer.treeItemId}"></girafe-tree-view-item>` )}
  </div>
  <div id="gutter" class="gutter"></div>
  <div id="hide" class="hide">
    <i class="fa-solid"></i>
  </div>
</div>
`; }
  

  layerManager: LayerManager;

  isAllExpanded: boolean = false;
  areAllLegendExpanded: boolean = true;

  get isSwiperVisible() {
    return this.state.layers.swipedLayers.left.length > 0 || this.state.layers.swipedLayers.right.length > 0;
  }

  constructor() {
    super('treeview');

    this.layerManager = LayerManager.getInstance();
  }

  registerEvents() {
    this.stateManager.subscribe('selectedTheme', () => this.onThemeChanged());
    this.stateManager.subscribe('layers.layersList', (oldLayers, newLayers) =>
      this.onLayersListChanged(oldLayers, newLayers)
    );
    this.stateManager.subscribe('layers.swipedLayers', () => super.render());
    this.stateManager.subscribe('treeview.advanced', () => super.render());
  }

  onThemeChanged() {
    if (this.state.selectedTheme != null) {
      this.state.layers.layersList = [...this.state.selectedTheme._layersTree];
      this.activateDefaultLayers(this.state.layers.layersList);
    } else {
      this.state.layers.layersList = [];
    }
  }

  onLayersListChanged(oldLayers: BaseLayer[], newLayers: BaseLayer[]) {
    super.render();
    // If we added a new group to the list of layers
    // Then we activate the layers that should be activated by default
    let addedLayers = newLayers;
    if (oldLayers) {
      addedLayers = newLayers.filter(
        (newLayer) => !oldLayers.find((oldLayer) => oldLayer.treeItemId === newLayer.treeItemId)
      );
    }
    this.activateDefaultLayers(addedLayers);
  }

  activateDefaultLayers(layers: BaseLayer[]) {
    for (const layer of layers) {
      this.layerManager.activateIfDefaultChecked(layer);
      if (layer instanceof LayerWms) {
        this.layerManager.initializeLegends(layer);
      }
      if (layer instanceof GroupLayer) {
        this.activateDefaultLayers(layer.children);
      }
    }
  }

  connectedCallback() {
    this.loadConfig().then(() => {
      this.render();
      super.girafeTranslate();
      this.registerEvents();
    });
  }

  render() {
    super.render();
    this.activateTooltips(false, [800, 0], 'right');
  }

  expandAll() {
    this.isAllExpanded = !this.isAllExpanded;
    this.#expandAllRecursive(this.state.layers.layersList);
    super.render();
  }

  #expandAllRecursive(layers: BaseLayer[]) {
    for (const layer of layers) {
      if (layer instanceof GroupLayer) {
        layer.isExpanded = this.isAllExpanded;
        this.#expandAllRecursive(layer.children);
      }
    }
  }

  toggleAllLegends() {
    this.areAllLegendExpanded = !this.areAllLegendExpanded;
    this.#toggleAllLegendsRecursive(this.state.layers.layersList);
    super.render();
  }

  #toggleAllLegendsRecursive(layers: BaseLayer[]) {
    for (const layer of layers) {
      if (layer instanceof LayerWms && layer.legend) {
        layer.isLegendExpanded = this.areAllLegendExpanded;
      } else if (layer instanceof GroupLayer) {
        this.#toggleAllLegendsRecursive(layer.children);
      }
    }
  }

  removeAll() {
    for (const layer of this.stateManager.state.layers.layersList) {
      this.layerManager.toggle(layer, 'off');
    }
    this.hideSwipe();
    this.state.layers.layersList = [];
    this.state.selectedTheme = null;
  }

  hideSwipe() {
    this.state.layers.swipedLayers = { left: [], right: [] };
    super.render();
  }
}

export default TreeViewComponent;
