import { html as uHtml } from 'uhtml';
import GirafeHTMLElement from '../../base/GirafeHTMLElement';

// https://css-tricks.com/a-complete-guide-to-dark-mode-on-the-web/

enum Toggle {
  toggle = 'toggle',
  replace = 'replace'
}

enum Mode {
  light = 'light',
  dark = 'dark'
}

class ColorSwitcherComponent extends GirafeHTMLElement {
  template = () => { return uHtml`<style>
.container {
  display: flex;
  flex-direction: column;
  height: 4.6rem;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: flex;
  flex-direction: column;
  vertical-align: middle;
  border-right: solid 1px var(--bkg-color);
  width: 4rem;
  height: 50%;
  text-align: center;
  background-color: var(--bkg-color-grad1);
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: relative;
  cursor: pointer;
  width: 3rem;
  height: 1.5rem;
  vertical-align: middle;
  background-color: var(--bkg-color);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  margin: 0 auto;
}

.slider:before {
  position: absolute;
  content: '';
  height: 1.3rem;
  width: 1.3rem;
  left: 4px;
  bottom: 0.1rem;
  background-color: var(--text-color-grad1);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #222;
}

input:focus + .slider {
  box-shadow: 0 0 1px #222;
}

input:checked + .slider:before {
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 1rem;
}

.slider.round:before {
  border-radius: 50%;
}

</style>
<link rel="stylesheet" href="lib/fontawesome/css/all.min.css" />

<div class="container">
  <label class="switch">
    <input
      id="frontendColorSwitcher"
      type="checkbox"
      onclick="${() => this.state.interface.darkFrontendMode = !this.state.interface.darkFrontendMode}" />
    <span tip="Dark mode on interface" class="slider round"></span>
  </label>
  <label class="switch">
    <input
      id="mapColorSwitcher"
      type="checkbox"
      onclick="${() => this.state.interface.darkMapMode = !this.state.interface.darkMapMode}" />
    <span tip="Dark mode on map" class="slider round"></span>
  </label>
</div>
`; }
  

  frontendColorSwitcher!: HTMLInputElement;
  mapColorSwitcher!: HTMLInputElement;

  // Select the theme preference from localStorage
  currentTheme: string | null = localStorage.getItem('theme');

  constructor() {
    super('colorswitcher');
  }

  render() {
    super.render();
    this.frontendColorSwitcher = this.shadow.querySelector('#frontendColorSwitcher') as HTMLInputElement;
    this.mapColorSwitcher = this.shadow.querySelector('#mapColorSwitcher') as HTMLInputElement;
    this.activateTooltips(false, [800, 0], 'top-end');
  }

  registerEvents() {
    this.stateManager.subscribe('interface.darkFrontendMode', () => this.onChangeDarkFrontendMode());
    this.stateManager.subscribe('interface.darkMapMode', () => this.onChangeDarkFrontendMode());
  }

  toggleClassList(val: boolean) {
    if (!val) {
      this.activateThemeMode(Mode.light, Toggle.replace);
    } else {
      this.activateThemeMode(Mode.dark, Toggle.replace);
    }
  }

  onChangeDarkFrontendMode() {
    // Interface
    const theme = this.state.interface.darkFrontendMode ? 'dark' : 'light';
    this.toggleClassList(this.state.interface.darkFrontendMode);
    this.frontendColorSwitcher.checked = this.state.interface.darkFrontendMode;
    localStorage.setItem('theme', theme);

    // Map
    this.mapColorSwitcher.checked = this.state.interface.darkMapMode;
  }

  initValue() {
    // In case the user has changed it already it's saved to local storage, let's reflect that in the UI
    if (this.currentTheme === 'dark') {
      this.activateThemeMode(Mode.dark, Toggle.toggle);
    } else if (this.currentTheme === 'light') {
      this.activateThemeMode(Mode.light, Toggle.toggle);
    } else {
      // If they haven't been explicit, let's check the media query
      const prefersDarkScheme = window.matchMedia('(prefers-color-scheme: dark)');
      if (prefersDarkScheme.matches) {
        this.activateThemeMode(Mode.dark, Toggle.toggle);
      } else {
        this.activateThemeMode(Mode.light, Toggle.toggle);
      }
    }
  }

  activateThemeMode(mode: Mode, toggle: Toggle) {
    // ...apply the .dark-theme class to override the default light styles

    if (toggle === Toggle.toggle) {
      document.body.classList.toggle(`${mode}-theme`);
    } else {
      const otherMode = mode === Mode.dark ? Mode.light : Mode.dark;
      document.body.classList.replace(`${otherMode}-theme`, `${mode}-theme`);
    }
    this.state.interface.darkFrontendMode = mode === Mode.dark;
  }

  connectedCallback() {
    this.loadConfig().then(() => {
      this.render();
      this.girafeTranslate();
      this.registerEvents();
      this.initValue();
    });
  }
}

export default ColorSwitcherComponent;
