import { html as uHtml } from 'uhtml';
import GirafeHTMLElement from '../../base/GirafeHTMLElement';
import MenuButtonComponent from '../menubutton/component';

class ProjectionComponent extends GirafeHTMLElement {
  template = () => { return uHtml`<style>

</style>
<girafe-menu-button id="menu-button" icon-style="fa-xl fa-solid fa-earth-europe" open="left">
  <div slot="menu-content">
    ${Object.entries(this.projections).map(([code, text]) => uHtml`
    <girafe-button
      text="${text}"
      icon-style="fa-xl fa-solid fa-earth-europe"
      onclick="${() => this.changeProjection(code)}"></girafe-button
    >` )}
  </div>
</girafe-menu-button>
`; }
  

  projections: Record<string, string> = {};
  #menuButton?: MenuButtonComponent;

  constructor() {
    super('projection');
  }

  get menuButton() {
    if (!this.#menuButton) {
      throw new Error('You called menuButton before render');
    }
    return this.#menuButton;
  }

  render() {
    // Get projections list and store it
    this.projections = this.configManager.Config.projections;
    for (const key in this.projections) {
      if (key.startsWith('//')) {
        delete this.projections[key];
      }
    }

    super.render();
    this.#menuButton = this.shadow.querySelector('#menu-button')!;

    // Hide menu button if only one projection
    if (Object.keys(this.projections).length == 1) {
      this.menuButton.hide();
    }
  }

  changeProjection(projection: string) {
    console.log('change projection', projection);
    this.state.projection = projection;
  }

  registerEvents() {
    this.stateManager.subscribe('projection', (_oldProjection: string, newProjection: string) =>
      this.onChangeProjection(newProjection)
    );
  }

  onChangeProjection(projection: string) {
    console.log('projection changed');
    const text = this.projections[projection];
    this.menuButton.setText(text);
    this.menuButton.closeMenu();
  }

  connectedCallback() {
    this.loadConfig().then(() => {
      this.render();
      super.girafeTranslate();
      this.registerEvents();
    });
  }
}

export default ProjectionComponent;
