import { html as uHtml } from 'uhtml';
import GirafeDraggableElement from '../../base/GirafeDraggableElement';
import GirafeLogo from './images/logo.png';

class AboutComponent extends GirafeDraggableElement {
  template = () => { return uHtml`<style>
#draggable {
  border-radius: 1px;
  box-shadow: var(--bx-shdw);
}

#header {
  padding: 0.5rem;
  cursor: grabbing;
  z-index: 10;
  background: var(--bkg-color-grad2);
  color: var(--text-color);
  text-align: center;
}

#content {
  padding: 0rem 2rem;
  width: 23rem;
  height: 13rem;
}

#close {
  cursor: pointer;
  float: right;
}

img {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

h3 {
  font-size: 1rem;
  font-weight: normal;
  margin: 0.2rem;
  color: var(--text-color);
}

a:link {
  color: var(--lnk);
}

a:visited {
  color: var(--lnk-visited);
}

a:hover {
  color: var(--lnk-hover);
}

a:active {
  color: var(--lnk-active);
  text-decoration: none;
  font-weight: normal;
}

</style>
<link rel="stylesheet" href="lib/fontawesome/css/all.min.css" />

<div id="draggable">
  <div id="header">
    About
    <div id="close">
      <i class="fa-solid fa-xmark"></i>
    </div>
  </div>
  <div id="content">
    <img alt="GeoGirafe logo" src="${this.logo}" />
    <h3>Version: ${this.version}</h3>
    <h3>Build: ${this.build}</h3>

    <h3>Date: ${this.date}</h3>
    <h3>
      Source:
      <a href="https://gitlab.com/geogirafe/gg-viewer" rel="noopener" target="_blank"
        >https://gitlab.com/geogirafe/gg-viewer</a
      >
    </h3>
  </div>
</div>
`; }
  

  loaded = false;
  version!: HTMLElement;
  build!: HTMLElement;
  date!: HTMLElement;
  logo: string = GirafeLogo;

  constructor() {
    super('about');
  }

  async loadVersionInfos() {
    if (!this.loaded) {
      // Version infos were not loaded yet.
      const response = await fetch('about.json');
      const versionInfos = await response.json();
      this.version = versionInfos.version;
      this.build = versionInfos.build;
      this.date = versionInfos.date;
      this.loaded = true;
      console.log(this.version);
      console.log(this.build);
      console.log(this.date);
    }

    this.render();
  }

  registerEvents() {
    this.stateManager.subscribe('interface.aboutVisible', (_oldValue: boolean, newValue: boolean) =>
      this.toggleAbout(newValue)
    );
  }

  toggleAbout(visible: boolean) {
    if (visible) {
      this.loadVersionInfos().then(() => {
        ((this.shadow.getRootNode() as ShadowRoot).host as HTMLElement).style.display = 'block';
      });
    } else {
      ((this.shadow.getRootNode() as ShadowRoot).host as HTMLElement).style.display = 'none';
    }
  }

  closeWindow() {
    this.state.interface.aboutVisible = false;
  }

  connectedCallback() {
    this.loadConfig().then(() => {
      this.render();
      this.girafeTranslate();
      this.makeDraggable();
      this.registerEvents();
    });
  }
}

export default AboutComponent;
