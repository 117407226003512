import { html as uHtml } from 'uhtml';
import GirafeResizableElement from '../../base/GirafeResizableElement';
import { Grid, html } from 'gridjs';
import I18nManager from '../../tools/i18nmanager';
import { niceCoordinates } from '../../tools/geometrytools';
import Geometry from 'ol/geom/Geometry.js';
import { getCenter } from 'ol/extent';
import { LineString, MultiLineString, MultiPoint, MultiPolygon, Point, Polygon } from 'ol/geom';
class SelectionGridComponent extends GirafeResizableElement {
  template = () => { return uHtml`<style>
:host,
#panel {
  min-height: 10rem;
  height: 16rem;
  background: var(--bkg-color);
}

#panel {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

#gutter {
  border: none;
  height: 6px;
  width: 100%;
  background: var(--bkg-color);
  position: absolute;
  top: 0;
  left: 0;
  cursor: row-resize;
}

#close {
  border: none;
  color: #777;
  padding: 0;
  margin: 0.5rem;
  width: 1rem;
  height: 1rem;
  line-height: 1rem;
  position: absolute;
  top: 0;
  right: 0;
  background-color: var(--bkg-color);
  cursor: pointer;
}

#close i:before {
  content: '\uf00d';
}

#close:hover {
  border-color: #000;
  color: #000;
}

#content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--bkg-color);
  padding: 0;
  margin: 0.5rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

#header {
  flex: 0;
}

.tab {
  border: solid 1px #ccc;
  display: inline-block;
  height: 2rem;
  line-height: 2rem;
  padding: 0 1rem;
  margin-right: 0.2rem;
  margin-bottom: 0.2rem;
  cursor: pointer;
  background-color: var(--bkg-color-grad2);
}

.tab.active {
  color: var(--text-color);
  background-color: var(--bkg-color);
}

#grid {
  overflow: hidden;
  flex: 1;
}

.gridjs-wrapper {
  overflow-x: hidden !important;
  height: 100% !important;
  width: 100% !important;
}

.gridjs-container {
  height: 100%;
  width: 100%;
}

.geo-type {
  margin-right: 0.5rem;
}

girafe-button {
  float: right;
  margin-left: 0.5rem;
  margin-top: 0.2rem;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background: #999;
}

.gridjs-table {
  background-color: var(--bkg-color) !important;
  color: var(--text-color);
}

td.gridjs-td {
  background-color: var(--bkg-color) !important;
}

th.gridjs-th {
  color: var(--text-color-grad2);
  background-color: var(--bkg-color-grad2) !important;
}

.gridjs-th-content {
  color: var(--text-color) !important;
}

</style>
<link rel="stylesheet" href="lib/gridjs/mermaid.min.css" />
<link rel="stylesheet" href="lib/fontawesome/css/all.min.css" />
<link rel="stylesheet" href="lib/font-gis/font-gis.css" />

<div id="panel">
  <div id="content">
    <div id="header"></div>
    <div id="grid"></div>
  </div>
  <div id="gutter"></div>
  <div id="close">
    <i class="fa-solid"></i>
  </div>
</div>
`; }
  

  locale = null;

  panel = null;
  content = null;
  header = null;
  grid = null;

  activeTab = null;

  typeToFeatures = {};

  constructor() {
    super('selectiongrid');
  }

  render() {
    super.render();

    // Bar is hidden per default
    this.panel = this.shadow.querySelector('#panel');
    this.content = this.shadow.querySelector('#content');
    this.header = this.shadow.querySelector('#header');
    this.grid = this.shadow.querySelector('#grid');
    this.panel.style.display = 'none';

    this.locale = this.configManager.Config.general.locale;

    this.activateTooltips(false, [800, 0], 'top-end');
  }

  registerEvents() {
    this.stateManager.subscribe('selection.selectedFeatures', (oldFeatures, newFeatures) =>
      this.onFeaturesSelected(newFeatures)
    );
    this.stateManager.subscribe('interface.selectionGridVisible', (oldValue, newValue) => this.togglePanel(newValue));
  }

  onFeaturesSelected(features) {
    this.header.innerHTML = '';
    if (this.isNullOrUndefined(features) || features.length <= 0) {
      this.state.interface.selectionGridVisible = false;
      this.grid.innerHTML = '';
      return;
    } else {
      this.state.interface.selectionGridVisible = false;
    }

    this.typeToFeatures = {};
    for (const f of features) {
      const id = f.getId();
      const featureType = id === undefined ? 'UNKNOWN' : id.split('.')[0];
      if (!(featureType in this.typeToFeatures)) {
        // Get columns
        const columns = [];
        for (const key in f.getProperties()) {
          // Exclude openlayers properties and geometry
          // TODO REG: Find the right geometry property using WFS Capabilities
          if (key !== 'boundedBy') {
            const column = {
              id: key,
              name: I18nManager.getInstance().getTranslation(key),
              formatter: this.formatCell.bind(this)
            };
            columns.push(column);
          }
        }

        // This feature type is not known yet
        this.typeToFeatures[featureType] = {
          columns: columns,
          data: [],
          features: []
        };
      }

      this.typeToFeatures[featureType].features.push(f);
      const data = [];
      // Content
      const properties = f.getProperties();
      for (const col of this.typeToFeatures[featureType].columns) {
        const value = properties[col.id];
        if (value instanceof Geometry) {
          const icons = this.getGeometryIcons(value);
          data.push(icons);
        } else {
          data.push(value);
        }
      }

      this.typeToFeatures[featureType].data.push(data);
    }

    for (const [key, value] of Object.entries(this.typeToFeatures)) {
      const tab = document.createElement('a');
      tab.text = I18nManager.getInstance().getTranslation(key);
      tab.className = 'tab';
      tab.id = key;
      tab.onclick = () => {
        this.displayGrid(key);
      };
      this.header.append(tab);
    }

    // Activate the first tab
    this.displayGrid(Object.keys(this.typeToFeatures)[0]);
  }

  getGeometryIcons(geometry) {
    console.log(geometry, typeof geometry);
    if(geometry.flatCoordinates.length !== 0){
      let icons;
      let coords;
      if (geometry instanceof Point) {
        icons = '<i class="geo-type fg-point fg-lg"></i>';
        coords = geometry.getFlatCoordinates();
        const niceCoords = niceCoordinates(coords);
        icons += `<span>E ${niceCoords[0]} / N ${niceCoords[1]}</span>`;
      } else if (geometry instanceof MultiPoint) {
        icons = '<i class="geo-type fg-multipoint fg-lg"></i>';
        if (geometry.getPoints.length === 1) {
          coords = geometry.getPoint(0).getFlatCoordinates();
          const niceCoords = niceCoordinates(coords);
          icons += `<span>E ${niceCoords[0]} / N ${niceCoords[1]}</span>`;
        } else {
          coords = getCenter(geometry.getExtent());
          icons += `<span>Multipoint</span>`;
        }
      } else if (geometry instanceof LineString || geometry instanceof MultiLineString) {
        icons = '<i class="geo-type fg-polyline-pt fg-lg"></i>';
        const length = (Math.round(geometry.getLength() * 100) / 100).toLocaleString(this.locale, {
          minimumFractionDigits: 2
        });
        icons += `<span>${length}&nbsp;m</span>`;
        coords = getCenter(geometry.getExtent());
      } else if (geometry instanceof Polygon || geometry instanceof MultiPolygon) {
        icons = '<i class="geo-type fg-polygon-pt fg-lg"></i>';
        const area = (Math.round(geometry.getArea() * 100) / 100).toLocaleString(this.locale, {
          minimumFractionDigits: 2
        });
        icons += `<span>${area}&nbsp;m<sup>2</sup></span>`;
        coords = getCenter(geometry.getExtent());
      } else {
        console.error('Unknown geometry type', geometry.getType());
        return;
      }

      icons += `<girafe-button icon-style="fa-sm fa-solid fa-magnifying-glass" 
                               size="extra-small" tip="Pan to geometry" tip-placement="right" 
                               class="transparent" 
                               state-action="position.center" 
                               data-value="[${coords[0]},${coords[1]}]"></girafe-button>`;

      return icons;
    } else {
      return '';
    }
  }

  formatCell(cell, row, column) {
    if(typeof cell === "object"){
      cell = cell._content_;
    }
    if (!this.isNullOrUndefinedOrBlank(cell)) {
      const lowerCell = cell.toLowerCase();
      if (
        (lowerCell.includes('<a') && lowerCell.includes('href')) ||
        (lowerCell.includes('<img') && lowerCell.includes('src')) ||
        lowerCell.includes('<girafe-button') ||
        lowerCell.includes('<table') ||
        (lowerCell.includes('<i') && lowerCell.includes('class'))
      ) {
        // For links and images, interpret html
        return html(cell);
      }
    }

    return cell;
  }

  searchGrid(cell, rowIndex, cellIndex) {
    console.log('SEARCH');
  }

  displayGrid(key) {
    this.grid.innerHTML = '';
    const features = this.typeToFeatures[key];

    new Grid({
      columns: features.columns,
      sort: true,
      fixedHeader: true,
      data: features.data,
      resizable: true,
      /*search: {
        selector: (cell, rowIndex, cellIndex) => { console.log('toto'); return cell;}
        //this.searchGrid.bind(this)
      },*/
      style: {
        th: {
          padding: '6px 10px'
        },
        td: {
          padding: '6px 10px'
        }
      }
    })
      .render(this.grid)
      .forceRender();

    this.activateTooltips(false, [800, 0], 'top-end');

    // Set style on tab
    const tab = this.shadow.getElementById(key);
    tab.classList.add('active');
    if (this.activeTab !== null) {
      this.activeTab.classList.remove('active');
    }
    this.activeTab = tab;
  }

  connectedCallback() {
    this.loadConfig().then(() => {
      this.render();
      super.girafeTranslate();
      this.registerEvents();
    });
  }

  closePanel() {
    this.state.interface.selectionGridVisible = false;
    this.state.selection.selectedFeatures = null;
  }

  togglePanel(visible) {
    if (visible) {
      this.panel.style.display = 'block';
      this.panel.getRootNode().host.style.display = 'block';
    } else {
      this.panel.style.display = 'none';
      this.panel.getRootNode().host.style.display = 'none';
    }
  }
}

export default SelectionGridComponent;
