import { html as uHtml } from 'uhtml';
import GirafeHTMLElement from '../../base/GirafeHTMLElement';
import ButtonComponent from '../button/component';

class ScaleComponent extends GirafeHTMLElement {
  template = () => { return uHtml`<style>
#content {
  background: var(--t-bkg);
  color: var(--text-color);
  width: fit-content;
  white-space: nowrap;
  padding: 0 0.5rem;
  font-size: 1rem;
}

label:after {
  content: ':';
  height: 2.3rem;
  line-height: 2.3rem;
}

span {
  font-weight: bold;
  height: 2.3rem;
  line-height: 2.3rem;
}

girafe-menu-button {
  display: inline-block;
}

#container {
  display: inline-flex;
  flex-direction: column;
  width: max-content;
}

</style>
<div id="content">
  <label for="scale" i18n="scale">Scale</label>
  <span id="scale"></span>
  <girafe-menu-button icon-style="fa-sm fa-solid fa-caret-up" size="small" open="up" class="transparent">
    <div slot="menu-content">
      <div id="container"></div>
    </div>
  </girafe-menu-button>
</div>
`; }
  

  scaleSpan = null;
  locale = null;
  container = null;
  scales = null;

  constructor() {
    super('scale');
  }

  render() {
    super.render();
    this.container = this.shadow.querySelector('#container');
    this.scaleSpan = this.shadow.querySelector('#scale');
    this.locale = this.configManager.Config.general.locale;
    this.scales = this.configManager.Config.map.scales;

    // Add options from themes
    this.scales.forEach((scale) => {
      const button = new ButtonComponent();
      button.setAttribute('text', this.formatScale(scale));
      button.setAttribute('size', 'large');
      button.setAttribute('state-action', 'position.scale');
      button.dataset.value = scale;
      this.container.appendChild(button);
    });
  }

  registerEvents() {
    this.stateManager.subscribe('position', () => this.onScaleChanged());
  }

  onScaleChanged() {
    if (this.state.position.scale !== null) {
      this.scaleSpan.innerHTML = this.formatScale(this.state.position.scale);
    } else {
      this.scaleSpan.innerHTML = '';
    }
  }

  formatScale(scale) {
    return '1:' + Math.floor(scale).toLocaleString(this.locale);
  }

  connectedCallback() {
    this.loadConfig().then(() => {
      this.render();
      super.girafeTranslate();
      this.registerEvents();
    });
  }
}

export default ScaleComponent;
