import { XmlTypes, xmlTypesStrList } from '../components/querybuilder/component';

export type LayerAttribute = {
  name: string;
  type: XmlTypes;
};

class ServerWfs {
  name: string;
  url: string;
  layers: Record<string, LayerAttribute[]>;
  initialized: boolean;

  constructor(name: string, url: string) {
    this.name = name;
    this.url = url;
    this.layers = {};
    this.initialized = false;
  }

  addLayerAttribute(layer: string, name: string, type: string) {
    if (!xmlTypesStrList.includes(type)) {
      console.warn(`Unmanaged attribute type: ${type}.`);
      return;
    }

    if (!(layer in this.layers)) {
      // Layer does not exists yet
      this.layers[layer] = [];
    }

    this.layers[layer].push({
      name: name,
      type: type as XmlTypes
    });
  }
}

export default ServerWfs;
