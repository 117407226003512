import { html as uHtml } from 'uhtml';
import GirafeHTMLElement from '../../base/GirafeHTMLElement';
import Basemap from '../../models/basemap';
import ShareManager from '../../tools/share/sharemanager';

class BasemapComponent extends GirafeHTMLElement {
  template = () => { return uHtml`<style>
#container {
  display: inline-flex;
  flex-direction: column;
  width: max-content;
}

</style>
<girafe-menu-button icon-style="fa-sm fa-solid fa-layer-group" size="small" open="up" class="transparent">
  <div slot="menu-content">
    <div id="container">
      ${Object.values(this.state.basemaps).map(basemap => uHtml.for(basemap)`
      <girafe-button
        text="${basemap.name}"
        size="large"
        class="border-top"
        onclick="${() => this.changeBasemap(basemap)}"></girafe-button
      >` )}
    </div>
  </div>
</girafe-menu-button>
`; }
  

  servers = {};
  basemapJson = {};

  shareManager: ShareManager;

  constructor() {
    super('basemap');

    this.shareManager = ShareManager.getInstance();

    this.configManager.loadConfig().then(() => {
      if (!this.configManager.Config.basemaps.show) {
        this.hide();
      }
    });
  }

  render() {
    super.render();
  }

  onBasemapsLoaded(basemaps: { [key: number]: Basemap }) {
    super.render();

    // Configure default basemap (only if there is no sharedstate)
    if (!this.shareManager.hasSharedState()) {
      for (const basemap of Object.values(basemaps)) {
        if (basemap.name === this.configManager.Config.basemaps.defaultBasemap) {
          this.state.activeBasemap = basemap;
          break;
        }
      }
    }
  }

  changeBasemap(basemap: Basemap) {
    console.log('change basemap', basemap);
    if (basemap.projection) {
      this.state.projection = basemap.projection;
    }
    this.state.activeBasemap = basemap;
  }

  registerEvents() {
    this.stateManager.subscribe(
      'basemaps',
      (_oldBasemaps: { [key: number]: Basemap }, newBasemaps: { [key: number]: Basemap }) =>
        this.onBasemapsLoaded(newBasemaps)
    );
  }

  connectedCallback() {
    this.loadConfig().then(() => {
      this.render();
      super.girafeTranslate();
      this.registerEvents();
    });
  }
}

export default BasemapComponent;
